import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import s from './Modale.module.scss';

function Modale(props)
{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <>
            <Button
                className='btn'
                onClick={handleShow}>
                Reset Passowrd
            </Button>

            <Modal className={s.modaleForm} size='lg' aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header className={s.modaleHeader}>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body className={s.formBody}>

                        <Form.Group className="mb-3" controlId="oldPassword">
                            <Form.Label>Old password :</Form.Label>
                            <Form.Control type="password" placeholder="Enter your old password" required />
                            <Form.Control.Feedback type="invalid">
                                Please type old password.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label className={s.lblPassword}>New password :</Form.Label>
                            <Form.Control type="password" placeholder="Enter your new pssword" required />
                            <Form.Control.Feedback type="invalid">
                                Please add new password.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="confirmPassword">
                            <Form.Label className={s.lblPassword}>Conform password :</Form.Label>
                            <Form.Control type="password" placeholder="Confirm password" required />
                            <Form.Control.Feedback type="invalid">
                                Please confirm password.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className={s.formFooter}>
                        <Button className={s.formCancel} variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className={s.formSubmit} variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default Modale;