import React, { Component } from "react";

import
{
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import "./Css/App.scss";

import Home from "../src/Home/App";
import Profile from "../src/Donor/App";
import Receptionist from "../src/Receptionist/App";
import CareGiver from "./CareGiver/App";
import DirectorGenerale from "../src/DirectorGenerale/App";
import DirectorCenter from "../src/DirectorCenter/App";

import Page404 from "./GlobaleComponents/Page404/Page404";

class App extends Component
{
  render()
  {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/donor">
            <Profile />
          </Route>
          <Route exact path="/receptionist">
            <Receptionist />
          </Route>
          <Route exact path="/caregiver">
            <CareGiver />
          </Route>
          <Route path="/directorgenerale">
            <DirectorGenerale />
          </Route>
          <Route exact path="/directorcenter">
            <DirectorCenter />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
