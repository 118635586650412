import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Search(props) {
  return (
    <Form>
      <Form.Group controlId="formBasicSearch">
        <div className="SearchContainer">
          <Form.Control
            type="text"
            placeholder={props.place}
            className="searchControl"
          />
          <div className="searchbtn">
            <Button
              variant="secondary"
              type="button"
              className="btnSeachCustom "
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}

export default Search;
