import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './Home/Home';
import Center from './Center/Center';
import Management from './Management/Management';
import Parameters from './Parameters/Parameters';
import { SideData } from './DirectorData/SideData';
import Page404 from '../GlobaleComponents/Page404/Page404';
import NavBarUser from "../GlobaleComponents/NavBarUser/NavBarUser";
import SideMenuBar from "../GlobaleComponents/SideMenuBarUser/SideMenuBar";
import { NotificationData } from "./DirectorData/NotificationData";
import  Setting from "../Settings/Setting/Setting";
import { Share } from "../Settings/Share/Share";
import { PrivacyPolicy } from "../Settings/Info/PrivacyPolicy";
import { TermConditions } from "../Settings/Info/TermConditions";

class App extends Component
{
    render()
    {
        return (
            <Router>
                <NavBarUser NavSearchBar={null} NotifData={NotificationData} />
                <SideMenuBar sideData={SideData} />
                <main className="py-4 main-container">
                    <Switch>
                        <Route exact path='/directorcenter'>
                            <Home />
                        </Route>
                        <Route exact path='/center/storage'>
                            <Center />
                        </Route>
                        <Route exact path='/management/employees'>
                            <Management />
                        </Route>
                        <Route exact path='/parameters/add'>
                            <Parameters />
                        </Route>

                        <Route exact path='/setting'>
                            <Setting />
                        </Route>
                        <Route exact path='/share'>
                            <Share />
                        </Route>
                        <Route exact path='/privacy_policy'>
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path='/terms'>
                            <TermConditions />
                        </Route>
                        <Route path="*">
                            <Page404 />
                        </Route>
                    </Switch>
                </main>
            </Router>
        );
    }
}

export default App;
