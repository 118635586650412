import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import logo from "../../../../logo.svg";
import { Link } from "react-router-dom";
import useForm2 from "./useForm2";
import validatefields from "./validationInfo2";

//http://127.0.0.1:8000/api/v1/auth/register
const FormRegister2 = () => {
  const { handelChange, values, errors, handelSubmit } =
    useForm2(validatefields);
  return (
    // <div className="container">
    <div className="formRegister2">
      <Form className="form-register2" onSubmit={handelSubmit}>
        <h2>More informations</h2>
        <Row>
          <Form.Group
            as={Col}
            controlId="formGroupCin"
            className="form-group-register2"
          >
            <Form.Control
              type="text"
              placeholder="CIN (*)"
              name="cin"
              className="form-control-register2"
              value={values.cin}
              onChange={handelChange}
            />
            {errors.cin && (
              <span style={{ color: "red", fontSize: "10px" }}>
                {errors.cin}
              </span>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="formGroupBirthlocation"
            className="form-group-register2"
          >
            <Form.Control
              type="text"
              className="form-control-register2"
              placeholder="birth location"
            />
          </Form.Group>
        </Row>
        <Form.Group controlId="formGroupCountry">
          <Form.Control
            as="select"
            defaultValue="country(*)"
            custom
            className="selectionCountry"
          >
            <option>Country(*)</option>
            <option>Maroc</option>
            <option>France</option>
          </Form.Control>
        </Form.Group>
        <Row className="form-group-register2">
          <Form.Group as={Col} sm={8} controlId="formGroupState">
            <Form.Control
              as="select"
              defaultValue="state(*)"
              custom
              className="selectionState"
            >
              <option>state(*)</option>
              <option>tanger-tetouan</option>
              <option>rabat-sale-zmour-zeire</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} sm={4} controlId="formGroupcity">
            <Form.Control
              as="select"
              defaultValue="city(*)"
              custom
              className="selectionCity"
            >
              <option>city(*)</option>
              <option>tanger</option>
              <option>rabat</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Form.Group
          controlId="formGroupAdress"
          className="form-group-register2"
        >
          <Form.Control
            type="text"
            className="form-control-register2-adress"
            placeholder="adress ligne 1"
          />
        </Form.Group>
        <Form.Group
          controlId="formGroupAdressZip"
          className="form-group-register2"
        >
          <Row>
            <Col xs={8}>
              <Form.Control
                type="text"
                className="form-control-register2"
                placeholder="adress ligne 2 (optional)"
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                className="form-control-register2"
                placeholder="Zip"
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group
          controlId="formGroupPhoneNumber"
          className="form-group-register2"
        >
          <Row>
            <Col sm={3}>
              <Form.Control
                as="select"
                defaultValue="+212"
                custom
                name="selection"
                value={values.selection}
                onChange={handelChange}
                className="selection-number"
              >
                <option value="+212">+212</option>
                <option>+213</option>
                <option>+16</option>
              </Form.Control>
              {errors.selection && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  {errors.selection}
                </span>
              )}
            </Col>
            <Col sm={6} >
              <Form.Control
                type="text"
                placeholder="600-000000(*)"
                name="tel"
                className="form-controle-tel"
                value={values.tel}
                onChange={handelChange}
              />
              {errors.tel && (
                <span style={{ color: "red", fontSize: "10px" }}>
                  {errors.tel}
                </span>
              )}
            </Col>
            <Col sm={3}>
              <Button type="submit" className="btn-send">
                send
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="formGroupConfirmation"
          className="form-group-register2"
        >
          <Form.Label column sm="6" className="lbl-confirm">
            Tape the confirmation message here
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              placeholder="6 (digits)"
              name="confirmTel"
              className="form-control-register2"
              value={values.confirmTel}
              onChange={handelChange}
            />
            {errors.confirmTel && (
              <span style={{ color: "red", fontSize: "10px" }}>
                {errors.confirmTel}
              </span>
            )}
          </Col>
        </Form.Group>
        <Link to="/login">
          <Button type="submit" variant="primary" className="btn-create">
            Confirm
          </Button>
        </Link>
        <hr></hr>
        <div className="div-info-register2">
          <label className="lbl-info">(*) Required fields</label>
          <label className="lbl-info-center">
            This informations are related to the center
          </label>
        </div>
      </Form>
      <span className="vertical-line-register2"></span>
      <img src={logo} alt="logo" className="logo-dl-register2" />
    </div>
    // </div>
  );
};

export default FormRegister2;
