import Tabs from "../../GlobaleComponents/Tabs/Tabs";
import React, { Component } from "react";

import { TabsCenterData } from "./Data/TabsCenterData";
import { PagesRouteCenterData } from "./Data/PagesRouteCenterData";

class Center extends Component
{
    render()
    {
        return (
           <>
                <Tabs TabsData={TabsCenterData} PagesRouteData={PagesRouteCenterData}/>
           </>
        );
    }
}

export default Center;
