import Employees from '../Employees/Employees';
import Add from '../Add/Add';

export const PagesRouteManagementData =
    [
        {
            page: <Employees />,
            link: '/management/employees'
        },
        {
            page: <Add />,
            link: '/management/add'
        },
    ]