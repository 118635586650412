import React, { Component } from "react";
import { SearchData } from "../Data/SearchData";
import Employee from "./Employee";

import s from './Employees.module.scss'
import { FaSearch } from "react-icons/fa";

class Employees extends Component
{
    render()
    {
        return (
            <div className={`${s.employees} container`} >
                <div className={s.inputSearch}>

                    <input className={`${s.input} form-control`} list='directorCenterId' placeholder='employee cin...' />
                    <datalist id='directorCenterId'>
                        {
                            SearchData.map((value) =>
                                <option value={value.option} key={value.key} />

                            )
                        }
                    </datalist>
                    <i className={s.iconSearch}>
                        <FaSearch />
                    </i>
                </div>

                <Employee id={1} />
            </div>
        );
    }
}

export default Employees;
