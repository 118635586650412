export const CityManagementData =
    [
        {
            option: 'Tetouan',
            key: '1'
        },
        {
            option: 'Tanger',
            key: '2'
        },
        {
            option: 'Fesmane',
            key: '3'
        },
        {
            option: 'Rabat',
            key: '4'
        },
        {
            option: 'Casablanca',
            key: '5'
        },
        {
            option: 'Laeyoune',
            key: '6'
        },
        {
            option: 'Guelmim',
            key: '7'
        },
        {
            option: 'Lagouira',
            key: '8'
        },
        {
            option: 'Marrakech',
            key: '9'
        },
        {
            option: 'Meknes',
            key: '10'
        },
        {
            option: 'Bojdour',
            key: '11'
        },
        {
            option: 'Tadla',
            key: '12'
        },
        {
            option: 'Nador',
            key: '13'
        },
        {
            option: 'Oujda',
            key: '14'
        },
        {
            option: 'Taza',
            key: '15'
        },
    ];