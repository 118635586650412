import React, { Component } from "react";
import InfoOne from "./infoOne";
import InfoTow from "./infoTow";
import InfoThree from "./infoThree";
import InfoFour from "./infoFour";

class GeneralInfo extends Component
{
  render()
  {
    return (
      <div className='container-fluid homePage'>
        <InfoOne />
        <InfoTow />
        <InfoThree />
        {/* <InfoFour /> */}
      </div>
    );
  }
}

export default GeneralInfo;
