import DonationSteps from "../donationSteps/DonationSteps";
import DonorReports from "../DonorReports/DonorReports";
import UserInfo from "../UserInfo/UserInfo";

export const PagesRouteProfileData =
    [
        {
            page: <UserInfo />,
            link: '/profile/userinfo'
        },
        {
            page: <DonorReports />,
            link: '/profile/reports'
        },
        {
            page: <DonationSteps />,
            link: '/profile/donation'
        },
    ]