import Members from "../../TeamPages/Members/Members";
import Posts from "../../TeamPages/Posts/Posts";
import Reports from "../../TeamPages/Reports/Reports";

export const PagesRouteTeamData =
    [
        {
            page: <Posts />,
            link: '/team/main/posts'
        },
        {
            page: <Members />,
            link: '/team/main/members'
        },
        {
            page: <Reports />,
            link: '/team/main/reports'
        },
    ]