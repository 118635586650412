export const AvatarData =
    [
        {
            value: 1,
            label: '<icon.FaSmile/>'
        },
        {
            value: 1,
            label: '<icon.FaSmile />'
        },
        {
            value: 1,
            label: '<icon.FaSmile />'
        },
        {
            value: 1,
            label: '<icon.FaSmile />'
        },
        {
            value: 'fa-500px',
            label: '&#xf26e'
        },
        {
            value: 'fa-address-book',
            label: '&#xf2b9;'
        },
		{
            value:'fa-address-book-o',
            label: '&#xf2ba;'
        },
    ]
