import React from "react";
import { Link } from "react-router-dom";
import Calendar from "./Calendar/Calendar";
import { TimeData } from "./Data/TimeData";

import styles from './DateTime.module.scss';

export function DateTime()
{

    return (
        <div className={styles.dateTime}>
            <div className={styles.calendarCard}>
                <h5 className={styles.title}>Choose Date</h5>
                <Calendar />
            </div>
            <div className={styles.timeCard}>
                <h5 className={styles.title}>Choose time</h5>
                <div className={`d-flex align-content-center flex-wrap ${styles.timeRow}`}>
                    {
                        TimeData.map((time, index) =>
                        {
                            return (
                                <div key={index} type='button' className={styles.timeCol}>{time.hour}</div>
                            )
                        })
                    }
                </div>
                <Link to="/appointment/stateregion/city/center/datetime/succes" className={`btn ${styles.btnPrimary}`}>Fix Appointment</Link>
                <Link to="/" className={`btn ${styles.btnPrimary} ${styles.previous}`}>Cancel</Link>
            </div>
        </div>
    )
}