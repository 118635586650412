import React, { Component } from 'react';
import Tabs from "../../../../GlobaleComponents/Tabs/Tabs";

import { TabsTeamData } from '../TeamPages/Data/TabsTeamData';
import { PagesRouteTeamData } from '../TeamPages/Data/PagesRouteTeamData';

class MainPageTeam extends Component
{
    render()
    {
        return (
            <>
                <Tabs TabsData={TabsTeamData} PagesRouteData={PagesRouteTeamData} />
            </>
        )
    }
}

export default MainPageTeam;