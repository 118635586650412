import Tabs from "../../GlobaleComponents/Tabs/Tabs";
import React, { Component } from "react";
import { TabsParametersData } from "./ParameterData/TabsParametersData";
import { PagesRouteParametersData } from "./ParameterData/PagesRouteParametersData";

class Parameters extends Component
{
    render()
    {
        return (
            <>
                <Tabs TabsData={TabsParametersData} PagesRouteData={PagesRouteParametersData} />
            </>
        );
    }
}

export default Parameters;