import Select from "../../../GlobaleComponents/Select/Select";
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { BloodTypeParametersData } from '../ParameterData/BloodTypeParametersData'

import style from './Add.module.scss';

export default function Add()
{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div className={`${style.add} container`}>
            <h5 className={style.title}>Add blood stats</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={`${style.form}`}>

                <Form.Group controlId="formBasicEmail" className={style.inputGroup}>
                    <Select items={BloodTypeParametersData} required/>
                    <Form.Control.Feedback type="invalid">
                        Please add a blood type.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="red blood cells quantity" min='0' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add red blood cells quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="cryoprecipitate quantity" min='0' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a cryoprecipitate quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="platelets quantity" min='0' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a platelets quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="frozen plasma quantity" min='0' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a frozen plasma quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className={style.btnSubmit}>
                    Add Blood Static
                </Button>
            </Form>
        </div>
    );
}