import React, { Component } from 'react';
import { FaShieldAlt } from 'react-icons/fa';
import { Avatar } from 'react-lorem-ipsum';
import { Link } from 'react-router-dom';

import s from './NavBarUser.module.scss';

class NavProfile extends Component
{

    render()
    {
        return (

            <li className={`${s.navItem} ${s.navProfile} nav-item`}>
                <Link to="/profile">
                    <div className='position-relative'>
                        <Avatar gender="all" className={s.avatar} width="40" height="40" alt='Avatar' />
                        <FaShieldAlt className={`${s.navProfileBadge} position-absolute top-100 start-0 translate-middle`} />
                    </div>
                </Link>
            </li>
        );
    };
};

export default NavProfile;