import { username } from "react-lorem-ipsum";

export const ReportData = [
    {
        title: 'TetInnova - Monday',
        members: `${username()}, ${username()}, ${username()}, ${username()} and ${username()}`,
        center: 'Center Rabat Agdal',
        city: 'Rabat',
        hour: '10:30 AM',
        day: 'Monday',
        month: 1,
        year: 2020,
        period: 20,
        type: 'AB-, O+, AB+, O',
    },
    {
        title: 'TetInnova - Friday',
        members: `${username()}, ${username()}`,
        center: 'Center Tanger Mghogha',
        city: 'Tanger',
        day: 'Friday',
        hour: '13:30 PM',
        month: 4,
        year: 2019,
        period: 30,
        type: 'AB+, O',
    },
    {
        title: 'TetInnova - Wedensday',
        members: `${username()}, ${username()} and ${username()}`,
        center: 'Center Agadir Sous blood',
        city: 'Agadir',
        day: 'Wedensday',
        hour: '16:20 PM',
        month: 10,
        year: 2018,
        period: 10,
        type: 'O+, AB+, O',
    },
    {
        title: 'TetInnova - Monday',
        members: `${username()}`,
        center: 'Center Ain ChouQ',
        city: 'Casa Blanca',
        day: 'Monday',
        hour: '09:20 AM',
        month: 8,
        year: 2018,
        period: 15,
        type: 'O',
    },
    {
        title: 'TetInnova - Tuesday',
        members: `${username()}`,
        center: 'Center Errachidia',
        city: 'Errachidia',
        day: 'Tuesday',
        hour: '09:20 AM',
        month: 3,
        year: 2017,
        period: 10,
        type: 'O',
    },
];