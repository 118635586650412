import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

import s from './Statistical.module.scss';

class ChartTwo extends Component
{
    render()
    {
        return (
            <div className={s.chartTwo}>
                <p>% of Blood type in Morocco</p>
                <Doughnut
                    data={{
                        labels: ["A", "B", "AB", "O", "O+"],
                        datasets: [
                            {
                                label: ["National Blod Pourcentage"],
                                data: ["8", "30", "18", "4", "40"],
                                backgroundColor: ["#FBA922", "#F0584A", "#2B5877", "#1194A8", "#1FC7B7"],
                                borderColor: "#bac8d9",
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

export default ChartTwo;
