import { username } from "react-lorem-ipsum";

export const ReportDonorData = [
    {
        title: 'TetInnova - Monday',
        name: username(),
        center: 'Center Saniat Rmel',
        city: 'Tetouan',
        hour: '10:30 AM',
        day: 'Monday',
        month: 1,
        year: 2020,
        period: 20,
        type: 'O-',
    },
    {
        title: 'TetInnova - Friday',
        name: username(),
        center: 'Center Saniat Rmel',
        city: 'Tetouan',
        day: 'Friday',
        hour: '13:30 PM',
        month: 4,
        year: 2019,
        period: 30,
        type: 'O-',
    },
    {
        title: 'TetInnova - Wedensday',
        name: username(),
        center: 'Center Saniat Rmel',
        city: 'Tetouan',
        day: 'Wedensday',
        hour: '16:20 PM',
        month: 10,
        year: 2018,
        period: 10,
        type: 'O-',
    },
    {
        title: 'TetInnova - Monday',
        name: username(),
        center: 'Center Saniat Rmel',
        city: 'Tetouan',
        day: 'Monday',
        hour: '09:20 AM',
        month: 8,
        year: 2018,
        period: 15,
        type: 'O-',
    },
    {
        title: 'TetInnova - Tuesday',
        name: username(),
        center: 'Center Saniat Rmel',
        city: 'Tetouan',
        day: 'Tuesday',
        hour: '09:20 AM',
        month: 3,
        year: 2017,
        period: 10,
        type: 'O-',
    },
];