import { Alert } from "@material-ui/lab";

export const NotificationData = [
    {
        notify: <Alert variant="filled" severity="error">An urgent case at Saniyat Al Raml Center (AB-)<br /><u>fix an appointment now</u></Alert>,
        date: '06-30-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: <Alert variant="filled" severity="warning">30% blood bank</Alert>,
        date: '05-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
    {
        notify: 'tristique nisi congue. Id sapien sit pellentesque phasellus porta.',
        date: '04-26-2021',
        link: '/parameters/request'
    },
];