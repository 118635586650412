import { Component } from "react";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const AddDerictorForm = () => {
  const {
    handleSubmit,
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    isValid,
  } = useFormik({
    initialValues: {
      ppr: "",
      city: "",
      startDate: formatDate(new Date()),
      idCenter: "",
    },
    validationSchema: Yup.object({
      ppr: Yup.string().trim().required("Required"),
      startDate: Yup.date(),
      idCenter: Yup.number(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="addCenterForm Mycard">
      <h5 className="addCenterForm-header">Fill the informations.</h5>
      <hr />
      <div className="addCenterForm-body">
        <Formik>
          <Form onSubmit={handleSubmit}>
            {/* PPR zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="ppr"
                placeholder="PPR"
                value={values.ppr}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  touched.ppr && errors.ppr
                    ? "is-invalid"
                    : touched.ppr
                    ? "is-valid"
                    : ""
                }
              />

              {touched.ppr && errors.ppr ? (
                <div class="invalid-feedback d-block">{errors.ppr}</div>
              ) : touched.ppr ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* starting date zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="date"
                name="startDate"
                value={values.startDate}
                onChange={handleChange}
              />
            </Form.Group>

            {/* city select */}

            <Form.Group>
              <Field
                component="select"
                className="form-select"
                name="city"
                onChange={handleChange}
              >
                <option>tetouan</option>
                <option>tanger</option>
                <option>rabat</option>
                <option>chaouen</option>
              </Field>
            </Form.Group>
            {/* center select */}

            <Form.Group>
              <Field
                component="select"
                className="form-select"
                name="idCenter"
                onChange={handleChange}
              >
                <option value="1">center 1</option>
                <option value="2">center 2</option>
                <option value="3">center 3</option>
                <option value="4">center 4</option>
              </Field>
            </Form.Group>

            <Button type="submit" className="btnAdd">
              Add
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
