import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import HomePage from "./components/homePage";
import Register from "./components/register";
import Register2 from "./components/register2";
import Login from "./components/login";

import Home from "../Donor/Pages/Home/Home";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./components/style/App.scss";
import { PrivacyPolicy } from "../Settings/Info/PrivacyPolicy";
import { TermConditions } from "../Settings/Info/TermConditions";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route  path="/login">
          <Login />
        </Route>
        <Route  path="/register">
          <Register />
        </Route>
        <Route  path="/register2">
          <Register2 />
        </Route>
        <Route  path="/privacy_policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms">
          <TermConditions />
        </Route>
          <Route path="/donor" render={() => <Redirect to="/donor" />}>
            <Home/>
          </Route>
      </Switch>
    </Router>
  );
}

export default App;
