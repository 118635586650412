import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';


import { Grid } from '@material-ui/core';

import { rows } from './Data/ProfileRowsData';
import SearchInput from '../../GlobaleComponents/AutoCompletSelectSearch/SearchInput';
import { FaSearch } from 'react-icons/fa';
import { ProfileSearchData } from './Data/ProfileSearchData';

import { useStyles } from "./TableProfileStyle";
import Modale from './Modale/Modale';

export default function TableProfile()
{
  const classes = useStyles();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'cin',
      headerName: 'CIN',
      width: 150,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 250,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 250,
      editable: false,
      headerAlign: 'center',
      textAlign: 'center'
    },
    {
      field: 'member_team',
      headerName: 'Member - Team',
      width: 250,
      editable: false,
      headerAlign: 'center',
      textAlign: 'center'
    },
    {
      field: 'confirm',
      headerName: 'Confirm',
      width: 250,
      renderCell: () => (
        <Modale />
      ),
    },
  ];

  return (
    <div className={`${classes.tableProfile} container`}>

      <div className={classes.inputSearch}>
        <SearchInput Options={ProfileSearchData} placeholder='search donor (cin)' id='donorCin' />
        <i className={classes.iconSearch}>
          <FaSearch />
        </i>
      </div>

      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.root}
        style={{ height: '70vh', backgroundColor: 'white' }}>
        <DataGrid
          density='comfortable'
          rows={rows}
          columns={columns}
        />
      </Grid>
    </div>
  );
}