import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import * as icon from 'react-icons/fa';

import style from './SideMenuBar.module.scss';

class SideMenuBar extends Component
{
    menuItems(items)
    {
        return items.map((item, index) =>
        {
            return (
                <NavLink key={index} to={item.link} className={style.menuItemsIconText} activeClassName={style.active}>
                    <i className={style.sideIcon}>{item.icon}</i><br />
                    <span className={style.itemTitle}>{item.title}</span>
                </NavLink>
            )
        })
    }

    render()
    {

        return (
            <div className={style.navMenuBar} id='SideMenuBar'>

                {this.menuItems(this.props.sideData)}

                <div className={style.menuItemsIconsBottom}>
                    <NavLink className={style.menuItemsIconText} to='/setting' activeClassName={style.active}>
                        <i className={style.sideIcon}><icon.FaCog /></i>
                    </NavLink>
                    <NavLink className={style.menuItemsIconText} to='/share' activeClassName={style.active}>
                        <i className={style.sideIcon}><icon.FaShareSquare /></i>
                    </NavLink>
                    <NavLink className={style.menuItemsIconText} to='/privacy_policy' activeClassName={style.active}>
                        <i className={style.sideIcon}><icon.FaInfoCircle /></i>
                    </NavLink>
                </div>
            </div>
        );
    };
};

export default SideMenuBar;