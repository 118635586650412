import NavBarUser from "../GlobaleComponents/NavBarUser/NavBarUser";
import SideMenuBar from "../GlobaleComponents/SideMenuBarUser/SideMenuBar";
import TableUpload from "./TableUpload/TableUpload";
import TableProfile from "./TableProfile/TableProfile";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Share } from "../Settings/Share/Share";
import Setting from "../Settings/Setting/Setting";
import { TermConditions } from "../Settings/Info/TermConditions";
import { PrivacyPolicy } from "../Settings/Info/PrivacyPolicy";
import { NotificationData } from "../Donor/Components/Data/NotificationData";
import {SideBarData} from './Data/SideBarData';

function App()
{
  return (
    <Router>
      <NavBarUser NotifData={NotificationData} />
      <SideMenuBar sideData={SideBarData} />
      <main className="py-4 main-container">
        <Switch>
          <Route path="/caregiver" exact component={TableProfile} />
          <Route path="/upload" exact component={TableUpload} />
          <Route path="/share" component={Share} />
          <Route path="/setting" component={Setting} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/terms" component={TermConditions} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
