import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { username } from 'react-lorem-ipsum';
import QRCode from 'react-qr-code';
import { useStyles } from './ModaleStyle';

function Modale(props)
{
    const classes = useStyles();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <>
            <Button
                className={classes.btnPrimary}
                onClick={handleShow}>
                Confirm
            </Button>

            <Modal className={classes.modale} size='sm' aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header className={classes.modaleHeader}>
                    <Modal.Title className='small'>Confirm Donor Posibility to Donate</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group className={`${classes.grpGrCodeUser} mb-3 text-center`} controlId="confirmAppointment">
                            <QRCode
                                size={200}
                                value='DonateLife (TetInovaTeam)'
                                bgColor='white'
                                fgColor='black'
                                className={classes.qrCode} />


                        </Form.Group>
                        <h5 className={classes.h5}>{username()}</h5>

                        <Form.Group className="mb-3" controlId="confirmPassword">
                            <Form.Control type="text" placeholder="cin" required />
                            <Form.Control.Feedback type="invalid">
                                Please add cin (*).
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className=''>
                        <Button className={classes.btnSecondary} variant="secondary" onClick={handleClose}>
                            Back
                        </Button>
                        <Button className={classes.btnPrimary} variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default Modale;