export const StateManagementData =
    [
        {
            option: 'Chaouia-Ouardigha',
            key: 'Chaouia-Ouardigha'
        },
        {
            option: 'Doukkala-Abda',
            key: 'Doukkala-Abda'
        },
        {
            option: 'Fes-Boulemane',
            key: 'Fes-Boulemane'
        },
        {
            option: 'Gharb-Chrarda-Beni Hssen',
            key: 'Gharb-Chrarda-Beni Hssen'
        },
        {
            option: 'Grand Casablanca',
            key: 'Grand Casablanca'
        },
        {
            option: 'Laeyoune-Boujdour-Sakia El Hamra',
            key: 'Laeyoune-Boujdour-Sakia El Hamra'
        },
        {
            option: 'Guelmim-Es Semara',
            key: 'Guelmim-Es Semara'
        },
        {
            option: 'Oued Ed-Dahab-Lagouira',
            key: 'Oued Ed-Dahab-Lagouira'
        },
        {
            option: 'Marrakech-Tensift-El Haouz',
            key: 'Marrakech-Tensift-El Haouz'
        },
        {
            option: 'Meknes-Tafilalet',
            key: 'Meknes-Tafilalet'
        },
        {
            option: 'Oriental',
            key: 'Oriental'
        },
        {
            option: 'Rabat-Sale-Zemmour-Zaer',
            key: 'Rabat-Sale-Zemmour-Zaer'
        },
        {
            option: 'Tadla-Azilal',
            key: 'Tadla-Azilal'
        },
        {
            option: 'Tangier-Tetouan',
            key: 'Tangier-Tetouan'
        },
        {
            option: 'Souss-Massa-Drae',
            key: 'Souss-Massa-Drae'
        },
        {
            option: 'Taza-Al Hoceima-Taounate',
            key: 'Taza-Al Hoceima-Taounate'
        },
    ];