import react, { Component } from "react";
import { Card } from "react-bootstrap";
import * as icon from "react-icons/fa";

function FilterCard(props) {
  return (
    <Card className="Mycard filCar">
      <div className="btn-option-member">
        <i type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <icon.FaEllipsisV />
        </i>
        <ul className="items dropdown-menu">
          <li className="item">Hide</li>
          <li className="item">Show</li>
        </ul>
      </div>
      <p>{props.text}</p>

      <div className="date">{props.date}</div>
    </Card>
  );
}

export default FilterCard;
