import React, { Component } from 'react';
import StaticInfo from './staticInfo';
import {Row, Col} from 'react-bootstrap';
class InfoContainer extends Component{
    render(){
        return(
            <Row className="row-info">
              <Col lg={6}>
                <hr />
                <p className="explication-section">
                  <h4 style={{textAlign:"center"}}>Blood statut for each month</h4>
                  This graphic represents the history of general blood saturation in Tetouan center
                  You can follow the evolution of donations over the last 12  months.
                </p>
              </Col> 
              <Col lg={6}>
                <StaticInfo />
              </Col>
            </Row>
        );
    };
};

export default InfoContainer;