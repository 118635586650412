import React, { Component } from "react";
import NavbarHead from "./childComponents/formRegister/navbarRegister";
import FormRegister from "./childComponents/formRegister/formRegister";
import Footer from "./childComponents/footer/footer";

class Register extends Component {
  render() {
    return (
      <div>
        <NavbarHead />
        <FormRegister />
        <Footer />
      </div>
    );
  }
}

export default Register;
