import React, { Component } from "react";
import { Form, Card, Button } from "react-bootstrap";
import Modale from "./Modale/Modale";

import y from './UserInfo.module.scss';

class UserInfo extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      disabled: true,
      userInfo: {
        firstName: "Ayoub",
        lastName: "Elyoussoufi",
        userName: "Ayoub.elyoussoufi",
        email: "Ayoubelyousfi@gmail.com",
        tel: "+212600235689",
        sex: "man",
        cin: "LC13656",
        birthday: "12/02/1998",
        birthLocation: "beniAhmed",
        adresse: "beniahmed charquia",
        city: "chefchaouen",
        state: "tanger-tetouan-alhoucima",
        zip: "91000",
      },
    };
  }
  handelDisable = () =>
  {
    this.state.disabled === true
      ? this.setState({ disabled: false })
      : this.setState({ disabled: true });
  };
  handelChange = (e) =>
  {
    let name = e.target.name;
    let val = e.target.value;
    this.setState({ [name]: val });
  };
  // handelSubmit = (event) => {
  //   event.preventDefault();
  // }
  render()
  {
    return (
      <div className={`${y.divProfile} container`}>
        <Button
          className={y.btnEditProfile}
          type="button"
          onClick={this.handelDisable}
        >
          Edit Profile
        </Button>
        <Form className={y.editForm}>
          <h2 className={y.legendSection}>User informations :</h2>
          <Card className={y.cardFormContainer}>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>First name :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                name="firstName"
                value={this.state.userInfo.firstName}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Last name :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.lastName}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>User name :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.userName}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>E-mail :</Form.Label>
              <Form.Control
                type="email"
                disabled={this.state.disabled}
                value={this.state.userInfo.email}
                onChange={this.handelChange}
              />
            </Form.Group>

            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Phone :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.tel}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Sex :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.sex}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>CIN : </Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.cin}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Birthday : </Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.birthday}
                onChange={this.handelChange}
              />
            </Form.Group>

            <div className={y.btnReset}>
              <Modale />
            </div>

          </Card>


          <h2 className={y.legendSection}>Additional informations :</h2>
          <Card className={y.cardFormContainer}>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Birth Location :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.birthLocation}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Adress :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.adresse}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>City : </Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.city}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>State :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.state}
                onChange={this.handelChange}
              />
            </Form.Group>
            <Form.Group className="fields">
              <Form.Label className={y.labelFields}>Zip :</Form.Label>
              <Form.Control
                type="text"
                disabled={this.state.disabled}
                value={this.state.userInfo.zip}
                onChange={this.handelChange}
              />
            </Form.Group>
          </Card>
          <div className={y.btnFields}>
            <Button type="button">Cancel</Button>
            <Button type="submit">Save</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default UserInfo;
