import React from 'react';
import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import StepOne from './Steps/StepOne/StepOne';
import StepTow from './Steps/StepTwo/StepTwo';
import StepThree from './Steps/StepThree/StepThree';

import s from './DonationSteps.module.scss';
import { FiArrowRight } from 'react-icons/fi';


function getSteps()
{
    return ['Scan Qr Code', 'Read Qr Code', 'Result'];
}

function getStepContent(step)
{
    switch (step)
    {
        case 0:
            return <StepOne />;
        case 1:
            return <StepTow />;
        case 2:
            return <StepThree />;
        default:
            return 'Unknown step';
    }
}

export default function DonationSteps()
{
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    const isStepOptional = (step) =>
    {
        return step === 1;
    };

    const isStepSkipped = (step) =>
    {
        return skipped.has(step);
    };

    const handleNext = () =>
    {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep))
        {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () =>
    {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () =>
    {
        if (!isStepOptional(activeStep))
        {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) =>
        {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () =>
    {
        setActiveStep(0);
    };

    return (
        <div className={`${s.donationSteps} container`}>

            <Link type="button" className={`${s.btnAppointment} btn`} to='/appointment/stateregion'>
                View Appointement
                <i className={s.iconAppointment}>
                    <FiArrowRight />
                </i>
            </Link>
            <br />

            <div className={s.stepsTop}>
                <Stepper activeStep={activeStep} className={s.stepper}>
                    {steps.map((label, index) =>
                    {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index))
                        // {
                        //     labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        // }
                        // if (isStepSkipped(index))
                        // {
                        //     stepProps.completed = false;
                        // }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div className={s.test}>
                    {activeStep === steps.length ? (
                        <div className={s.test2}>
                            <Typography className={s.instructions}>
                                All steps completed - you&apos;re finished (now you can wait until 6 months to start a new donation).<br />
                                Thank You ♥
                            </Typography>
                            <button onClick={handleReset} className={`${s.btnReset} btn`}>
                                Reset
                            </button>
                        </div>
                    ) : (
                        <div className={s.test3}>
                            <Typography className={s.instructions} >{getStepContent(activeStep)}</Typography>
                            <div>
                                <button disabled={activeStep === 0} onClick={handleBack} className={`${s.btnBack} btn`}>
                                    Back
                                </button>
                                {/* {isStepOptional(activeStep) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSkip}
                                    className={classes.button}
                                >
                                    Skip
                                </Button>
                            )} */}

                                <button
                                    onClick={handleNext}
                                    className={`${s.btnNext} btn`}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}