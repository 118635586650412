import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Notifications from '@material-ui/icons/Notifications';

import s from './NavNotification.module.scss';
import { FaEllipsisH, FaFilter } from 'react-icons/fa';

class NavNotification extends Component
{
    render()
    {
        const nbNotif = this.props.NotificationData.length;

        return (
            <li className={`${s.navItemNotify}`}>
                <Link className={`${s.navLinkNotify} nav-link`} to="/dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <Badge badgeContent={nbNotif} color="error">
                        <Notifications fontSize='small' />
                    </Badge>
                </Link>
                <ul className={`${s.dropdownMenuNotify} dropdown-menu`} aria-labelledby="navbarDropdown">
                    <div className={s.filter}>
                        <i className={s.iconFilter}>
                            <FaFilter />
                        </i>
                        <i className={s.iconEllipseH}>
                            <FaEllipsisH />
                        </i>
                    </div>
                    <div className={s.notifyPadding}>
                        {
                            this.props.NotificationData.map(
                                (value, key) =>
                                    <li className={s.notif} key={key}>
                                        <Link className={s.linkNotify} to={value.link}>
                                            <div className={s.textNotify}><small>{value.notify}</small></div><br />
                                            <em className={s.dateNotify}><small>{value.date}</small></em>
                                        </Link>
                                    </li>
                            )
                        }
                    </div>
                </ul>
            </li>
        );
    };
};

export default NavNotification;