import React, { Component } from "react";
import logo from "../../../../logo.svg";
import FormInput from "./formInput";
class FormLogin extends Component {
  render() {
    return (
      <div className="container">
        <div className='formLogin'>
          <img src={logo} alt="logo" className="logo-dl" />
          <span className="vertical-line"></span>
          <FormInput />
        </div>
      </div>
    );
  }
}

export default FormLogin;
