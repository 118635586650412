import React, { Component, useState } from 'react';
import { username } from 'react-lorem-ipsum';
import * as icon from 'react-icons/fa';
import { PostData } from './Data/PostData';

import s from './Posts.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

const ReadMore = ({ children }) =>
{
    const div = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () =>
    {
        setIsReadMore(!isReadMore);
    };
    return (
        <div className={s.seeMore}>
            <div onClick={toggleReadMore} className={s.readOrHide}>
                {isReadMore ? <div className={s.seeMoreClose}><icon.FaCaretRight className={s.icon} /><em className={s.moreComments}>see comments {username()} and {username()} ...</em></div> : <icon.FaCaretDown className={s.seeMoreOpen} />}
            </div>
            {isReadMore ? null : div}

        </div>
    );
};

class NewPost extends Component
{
    render()
    {
        return (
            <>
                {
                    PostData.map((value, key) =>
                        <div className={s.newPosts} key={key}>
                            <div className={`${s.card} card`}>
                                <div className={`${s.cardHeader} card-header`}>
                                    <h5 className="p-0"><strong>{value.userpost}</strong></h5>
                                    <div className={s.btnOptionPosts}>
                                        <i className={s.notifyIcon} type='button' data-bs-toggle="dropdown" aria-expanded="false"><icon.FaEllipsisV /></i>
                                        <ul className={`${s.items} dropdown-menu`}>
                                            <li className={s.item}>Delete</li>
                                            <li className={s.item}>Edit</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={`${s.cardBodyText} card-body`}>
                                    <div className={s.description}><p className='card-text'>{value.description}</p></div>
                                    <div>

                                        <FormControlLabel
                                            control={<Checkbox className={s.btnLike} icon={<FavoriteBorder />}
                                                checkedIcon={<Favorite />}
                                                name="checkedB" />}
                                            className={s.likeCount}
                                            label={<em className={s.likeCount}>{value.userpost}, {value.likes}</em>}
                                        />

                                    </div>
                                </div>
                                <div className={`${s.cardBodyComments} card-body`}>

                                    <ReadMore>
                                        {
                                            value.comments.map((commentValue, commentKey) =>
                                                <div className={s.comments} key={commentKey}>
                                                    <div className={s.commentsOption}>
                                                        <div className={s.userComment}>{commentValue.username}</div>
                                                        <i className={s.notifyIcon} type='button' data-bs-toggle="dropdown" aria-expanded="false"><icon.FaEllipsisV /></i>
                                                        <ul className={`${s.items} dropdown-menu`}>
                                                            <li className={s.item}>Delete</li>
                                                            <li className={s.item}>Edit</li>
                                                        </ul>
                                                    </div>
                                                    <div className={s.userCommentText}><small>{commentValue.comment}</small></div>
                                                </div>
                                            )}
                                        <form>
                                            <div className={`${s.postComments} input-group`}>
                                                <input type="text" className={`${s.formControl} form-control`} placeholder="comments.." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <button type='submit' className={`${s.inputGroupText} btn`} id="basic-addon2"><icon.FaTelegramPlane /></button>
                                            </div>
                                        </form>
                                    </ReadMore>
                                </div>
                            </div>
                        </div>
                    )}
            </>
        )
    }
}

export default NewPost;