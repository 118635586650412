import { Component } from "react";
import { Container } from "react-bootstrap";
import Centers from "./Center/centers";
import Derictors from "./Derictor/derictors";

import { AddCenterForm } from "../AddCenterForm/AddCenterForm";
import { AddDerictorForm } from "../AddDerictorForm/AddDerictorForm";
import {
  Route,
  Switch,
  NavLink,
  Redirect,
} from "react-router-dom";

class Main extends Component {
  render() {
    return (
      <div className="main">
        <div className="team-tabs">
          <NavLink
            className="team-tabs-item"
            to="/directorgenerale/management/centers"
            activeClassName="active"
          >
            Centers
          </NavLink>
          <NavLink
            className="team-tabs-item"
            to="/directorgenerale/management/derictors"
            activeClassName="active"
          >
            Derictrors
          </NavLink>
        </div>

        <Container>
          <Switch>
            <Route path="/directorgenerale/management" exact>
              <Redirect to="/directorgenerale/management/centers" />
            </Route>
            <Route path="/directorgenerale/management/centers" exact>
              <Centers />
            </Route>
            <Route path="/directorgenerale/management/derictors" exact>
              <Derictors />
            </Route>

            <Route path="/directorgenerale/management/centers/add-center">
              <AddCenterForm />
            </Route>

            <Route path="/directorgenerale/management/derictors/add-derictor">
              <AddDerictorForm />
            </Route>
          </Switch>
        </Container>
      </div>
    );
  }
}

export default Main;
