import React, { Component } from "react";
import { FaDownload } from "react-icons/fa";
import { FcOk } from "react-icons/fc";
import QRCode from "react-qr-code";

import s from "./StepOne.module.scss";

class StepOne extends Component
{
  render()
  {
    return (
      <div className={s.steps}>
        <h5 className='text-center'>for a successful donation, you must complete the following steps successfully.</h5>
        <div className={`${s.card} card mb-3`}>
          <div className="row g-0">
            <div className="col-md-8">
              <div className={`${s.cardBody} card-body`}>

                <h5 className="card-title">First Step</h5>
                <i className={s.stepIcon}><FcOk /></i>
                <p className="card-text">
                  <small className="text-muted">you have to scan this QR code by blood
                    center. By scanning this code with the center, your
                    information will be verified in the center. Please do not
                    share this code with others
                  </small>
                </p>

                <h5 className="card-title">Second Step</h5>
                {/* <i className={s.stepIcon}><FcOk /></i> */}
                <p className="card-text">
                  <small className="text-muted">Now, you have to scan the QR code from the
                    screen of the center to confirm the whole process of donation.
                  </small>
                </p>

                <h5 className="card-title">Third Step</h5>
                {/* <i className={s.stepIcon}><FcOk /></i> */}
                <p className="card-text">
                  <small className="text-muted">You will receive a report after the donation
                    process that contains all the information related to the last
                    donation you made.
                  </small>
                </p>
              </div>
            </div>

            <div className={`${s.qrCodeUserName} col-md-2`}>
              <QRCode
                size={200}
                value='DonateLife (TetInovaTeam)'
                bgColor='white'
                fgColor='black' />
            </div>
          </div>
        </div>

        <button type="button" className={`${s.btnDownload} btn`}>
          <FaDownload />
        </button>
      </div>
    );
  }
}

export default StepOne;
