import React, { Component } from "react";
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';


import s from './Tabs.module.scss';

class Tabs extends Component
{
    render()
    {
        return (
            <Router>
                <div className={s.mainPage}>
                    <div className={s.tabs}>
                        {
                            this.props.TabsData.map((value, key) =>
                                <NavLink key={key} className={s.tabsItem} to={value.link} activeClassName={s.active}>{value.label}</NavLink>
                            )
                        }
                    </div>
                    <Switch>
                        {
                            this.props.PagesRouteData.map((value, key) =>
                                <Route exact path={value.link} key={key}>
                                    {value.page}
                                </Route>
                            )
                        }
                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Tabs;