import React, { Component } from "react";
import { Navbar, Form, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoGlobeOutline } from "react-icons/io5";
import logo from "../../../../logo.svg";

class NavbarHead extends Component {
  render() {
    return ( 
      <Navbar className="navbar-register1" expand="lg">
        <Link to="/">
          <img src={logo} className="App-logo-register1" alt="logo" />
        </Link>
        <Navbar.Brand className="nv-brand">DoniteLife</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="collapse">
          <Form inline className="navFormRegister1">
            <NavDropdown title={<IoGlobeOutline />} id="basic-nav-dropdown" className="langue">
              <NavDropdown.Item href="#action/3.3">English</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Français</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Arabic</NavDropdown.Item>
            </NavDropdown>
            <Link to="/login">
              <Button className="btn-login-register1">Login</Button>
            </Link>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavbarHead;
