import React from "react";
import { Link } from "react-router-dom";
import Calendar from "../../Donor/Pages/Appointment/DateTime/Calendar/Calendar";
import { TimeData } from "../../Donor/Pages/Appointment/DateTime/Data/TimeData";
import s from "./Calendar.module.scss";

export function Calendary() {
  return (
    <div className={s.dateTime}>
      <div className={s.timeCard}>
        <h5 className={s.title}>Choose Date</h5>
        <Calendar />
      </div>
      <div className={s.timeCard}>
        <h5 className={s.title}>Choose time</h5>
        <div className={`${s.timeRow} d-flex align-content-center flex-wrap`}>
          {TimeData.map((time, index) => {
            return (
              <div key={index} type="button" className={s.timeCol}>
                {time.hour}
              </div>
            );
          })}
        </div>
        <Link
          to="/appointment/stateregion/city/center/datetime/succes"
          className={s.btn}
        >
          Fix Appointment
        </Link>
        <Link to="/" className={`${s.btn} ${s.cancel}`}>
          Cancel{" "}
        </Link>
      </div>
    </div>
  );
}
