import React, { Component } from "react";
import * as icon from "react-icons/fa";
import {EmployeeData} from '../Data/EmployeeData';
import s from './Employee.module.scss';

class Employee extends Component
{
    render()
    {
        return (
            <>
                {
                    EmployeeData.map((value) =>

                        <div className={`${s.card} card mb-3`} key={value.id}>
                            <div className="row g-0">
                                <div className={`${s.avatar} col-md-4`}>
                                    <div className={s.memberUsername}>
                                        {value.image}
                                        <br /><br /><p>CIN: {value.cin}</p>
                                    </div>
                                </div>
                                <div className={`${s.memberInfo} col-md-8`}>
                                    <div className={s.btnOptionMember}>
                                        <i type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                            <icon.FaEllipsisV className={s.iconV} />
                                            <icon.FaEllipsisH className={s.iconH} />
                                        </i>
                                        <ul className={`${s.items} dropdown-menu`}>
                                            <li className={s.item}>Delete</li>
                                            <li className={s.item}>Edit</li>
                                        </ul>
                                    </div>
                                    <div className='card-body'>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                User Name:: {value.username}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Birthday:: {value.birthday}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Name:: {value.firstName}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Familly Name:: {value.lastName}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Nick Name:: {value.jobTitle}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Phone Number:: {value.phoneNumber}
                                            </span>
                                            <hr className={s.hr} />
                                        </div>
                                        <div className={s.cardBodyItems}>
                                            <span className="card-text">
                                                Email:: {value.email}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className={s.cardBodyItems}>
                                    <hr className={s.hr} />
                                    <span className="card-text">
                                        PPR:: {value.ppr}
                                    </span>
                                </div>
                                <div className={s.cardBodyItems}>
                                    <hr className={s.hr} />
                                    <span className="card-text">
                                        Hospital:: {value.hospital}
                                    </span>
                                </div>
                                <div className={s.cardBodyItems}>
                                    <hr className={s.hr} />
                                    <span className="card-text">
                                        State:: {value.state}
                                    </span>
                                </div>
                                <div className={s.cardBodyItems}>
                                    <hr className={s.hr} />
                                    <span className="card-text">
                                        City:: {value.city}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}

export default Employee;
