import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
class InfoThree extends Component {
  render() {
    return (
      <div className="div-info3-home">
        <div className="div-info3-line">
          <Bar
            data={{
              labels: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
              datasets: [
                {
                  label: ["National Blood Pourcentage"],
                  data: ["43", "6", "7", "2", "4", "1", "32", "6"],
                  backgroundColor: ["#38003D", "#F01973", "#983DB2", "#FB4F47"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
        <div className="div-info3-description">
          <hr />
          <p>
            <h4>Pourcentage of blood types in Morocoo</h4>
            Percentage of blood types in Morocco Morocco is similar to the world
            structure; we can constate that by the percentages which are near to
            worldwide ones this graphic represents the percentage of blood types
            in morocco The dominant one is A+ while AB- is the rarest one.
          </p>
        </div>
      </div>
    );
  }
}

export default InfoThree;
