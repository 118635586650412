import React, { Component } from "react";
import Footer from "./childComponents/footer/footer";
import Header from "./childComponents/header/header";
import GeneralInfo from './childComponents/homePage/generalInfo';

class HomePage extends Component {
  render() {
    return (
      <>
        <Header />
        <GeneralInfo/>
        <Footer />
      </>
    );
  }
}

export default HomePage;
