import { FaHome, FaUpload } from "react-icons/fa";

export const SideBarData = [
    {
        title: "Home",
        link: "/caregiver",
        icon: <FaHome />,
        Name: "menuItemsIconText",
    },
    {
        title: "Upload report",
        link: "/upload",
        icon: <FaUpload />,
        Name: "menuItemsIconText",
    },
];