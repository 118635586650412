import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo.svg'

import s from './NavBarUser.module.scss';

class Logo extends Component
{
    render()
    {
        return (
            <>
                <Link className={`${s.navBarBrand} navbar-brand`} to="/donor/home">
                    <img className={`${s.navBarLogo}`} src={logo} alt="Donate Life Logo" />
                </Link>
            </>
        );
    };
};

export default Logo;