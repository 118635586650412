import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class StaticInfo extends Component {
  render() {
    return (
      <div>
        <Bar
          className="blood-bar"
          data={{
            labels: [
              "January",
              "Fabruary",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            datasets: [
              {
                label: "Blood Pourcentage",
                data: [40, 53, 12, 22, 60, 58, 40, 70, 65, 35, 42, 75],
                backgroundColor: ["#B3445E", "#FFF894", "#FF7A99", "#61D5FF", "#4D97B3", "#BF6980", "#84B8D9", "#D9A25F", "#734226", "#0D0D0D", "#A4CFCB", "#996868"],
                borderColor: "#bac8d9",
                borderWidth: 1,
              },
            ],
          }}
          width={300}
          height={150}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default StaticInfo;
