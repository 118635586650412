import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Register from './Register/Register'
import MainPageTeam from './TeamPages/MainPageTeam';

import s from './Team.module.scss';

class Team extends Component
{
    render()
    {
        return (
            <Router>
                <div className={`${s.team} container`}>
                    <Switch>
                        <Route exact path='/team/register'>
                            <Register />
                        </Route>
                        <Route exact path='/team/main/posts'>
                            <MainPageTeam />
                        </Route>
                    </Switch>
                </div>
            </Router >
        )
    }
}

export default Team;