export const RegionData =
    [
        {
            value: 'Chaouia-Ouardigha',
            label: 'Chaouia-Ouardigha'
        },
        {
            value: 'Doukkala-Abda',
            label: 'Doukkala-Abda'
        },
        {
            value: 'Fes-Boulemane',
            label: 'Fes-Boulemane'
        },
        {
            value: 'Gharb-Chrarda-Beni Hssen',
            label: 'Gharb-Chrarda-Beni Hssen'
        },
        {
            value: 'Grand Casablanca',
            label: 'Grand Casablanca'
        },
        {
            value: 'Laeyoune-Boujdour-Sakia El Hamra',
            label: 'Laeyoune-Boujdour-Sakia El Hamra'
        },
        {
            value: 'Guelmim-Es Semara',
            label: 'Guelmim-Es Semara'
        },
        {
            value: 'Oued Ed-Dahab-Lagouira',
            label: 'Oued Ed-Dahab-Lagouira'
        },
        {
            value: 'Marrakech-Tensift-El Haouz',
            label: 'Marrakech-Tensift-El Haouz'
        },
        {
            value: 'Meknes-Tafilalet',
            label: 'Meknes-Tafilalet'
        },
        {
            value: 'Oriental',
            label: 'Oriental'
        },
        {
            value: 'Rabat-Sale-Zemmour-Zaer',
            label: 'Rabat-Sale-Zemmour-Zaer'
        },
        {
            value: 'Tadla-Azilal',
            label: 'Tadla-Azilal'
        },
        {
            value: 'Tangier-Tetouan',
            label: 'Tangier-Tetouan'
        },
        {
            value: 'Souss-Massa-Drae',
            label: 'Souss-Massa-Drae'
        },
        {
            value: 'Taza-Al Hoceima-Taounate',
            label: 'Taza-Al Hoceima-Taounate'
        },
    ];