export const TabsCenterData =
    [
        {
            label: 'Storage',
            link: '/center/storage'
        },
        {
            label: 'Edit Messages',
            link: '/center/editmessages'
        },
    ]