import React from "react";
import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle } from 'react-icons/io';

export default function CalendarHeader({ value, onChange }) {
    function currMonthName() {
        return value.format("MMMM");
    }

    function currYear() {
        return value.format("YYYY");
    }

    function prevMonth() {
        return value.clone().subtract(1, "month");
    }

    function nextMonth() {
        return value.clone().add(1, "month");
    }

    function thisMonth() {
        return value.isSame(new Date(), "month");
    }

    return (
        <div className="header">
            <span className="btn-previous" onClick={() => !thisMonth() && onChange(prevMonth())}>
                {!thisMonth() ? <i ><IoMdArrowDropleftCircle type='button'/></i> : null}
            </span>
            <span className="current">
                {currMonthName()} {currYear()}
            </span>
            <span className="next" onClick={() => onChange(nextMonth())}>
                <i><IoMdArrowDroprightCircle type='button'/></i>
            </span>
        </div>
    );
}
