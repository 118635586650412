import React, { Component } from 'react';
import DonorReport from './DonorReport';
import { FaFilter } from 'react-icons/fa';

import s from './DonorReports.module.scss';

class Reports extends Component
{
  render()
  {
    return (
      <div className={`${s.reports} container`}>
        <div className={s.line}>
          <hr className={s.lineHr} />
          <i className={s.btnFilter} type='button' data-bs-toggle="dropdown" aria-expanded="false">Filter <FaFilter /></i>
          <ul className={`${s.items} dropdown-menu`}>
            <li className={s.item}>By Name</li>
            <li className={s.item}>By Date</li>
          </ul>
        </div>
        <div>
          <DonorReport />
        </div>
      </div>
    )
  }
}

export default Reports;