import React, { Component } from 'react';
import NewPost from './NewPosts';
import { IoCalendarSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import s from './Posts.module.scss';
import Modale from '../Members/Modale/Modale';

class Posts extends Component
{
    render()
    {
        const style = {
            backgroundColor: '#32aeb8',
            padding: '10px',
            color: '#fff',
            fontWeight: 'bold',
            float: 'right',
            marginBottom: '16px',
            border: '1px solid #bac8d9',
        };

        return (
            <div className={`${s.posts} container`}>
                <div className={s.form}>
                    <form className={s.form}>
                        <textarea className={s.textArea} id="news" name="news" rows="4" cols="50" placeholder="what's news" />
                        <input type='submit' className={`${s.btnPrimary} btn`} value='Post' />
                    </form>
                </div>

                <NewPost />

                <div className={s.teamFixAppointmenet}>
                    <Link type='button' to='/appointment/stateregion' className={`${s.btnRight} btn`}>
                        <i className={s.icon}><IoCalendarSharp /></i>
                    </Link><br/>
                    <Modale buttonText={''} buttonStyle={style} title={'Add Team Member'}/>
                </div>
            </div>
        )
    }
}

export default Posts;