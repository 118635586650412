import react, { Component } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import Search from "../Search/search";
import InfoCard from "../Card/card";
import Filter from "../Filter/filter";
import AddNew from "../AddNewBtn/AddNew";

class Comm extends Component {
  state = {
    centers: [
      {
        name: "Center of: Tetouan ",
        adress: "Adress : Saniya dermal",
        param1: "Centre director : Dr...",
        param2: "Numbre of Staff : 9 person",
        param3: "Number of Emergencies per monthe : 18 Emergencies",
      },
      {
        name: "Center of: Tanger ",
        adress: "Adress : Rue fès alwilaya",
        param1: "Centre director : Dr....",
        param2: "Numbre of Staff : 15 person",
        param3: "Number of Emergencies per monthe : 25 Emergencies",
      },
      {
        name: "Center of: CasaBlanca ",
        adress: "Adress : Ain Chouk",
        param1: "Centre director : Dr....", 
        param2: "Numbre of Staff : 35 person",
        param3: "Number of Emergencies per monthe : 50 Emergencies",
      },
    ],
    showFilterCard: false,
  };

  handleShowFilterCard = () => {
    this.setState({ showFilterCard: !this.state.showFilterCard });
  };

  render() {
    return (
      <div className="customContainerPa">
        <div className={this.state.showFilterCard ? "overlay" : ""}></div>
        <div
          className={
            this.state.showFilterCard ? "filterParentCenter" : "filterParent"
          }
        >
          <AddNew
            icon={faFilter}
            text="Filter"
            onClick={this.handleShowFilterCard}
            className={this.state.showFilterCard ? "hide" : ""}
          />
          <Filter
            onClick={this.handleShowFilterCard}
            className={this.state.showFilterCard ? "" : "hide"}
          ></Filter>
        </div>
        <div className="customContainer">
          <Search place="search for derictor by name or by PPR"></Search>
          <ul className="ulCards">
            {this.state.centers.map((item, index) => (
              <li>
                <InfoCard
                  name={item.name}
                  adress={item.adress}
                  param1={item.param1}
                  param2={item.param2}
                  param3={item.param3}
                ></InfoCard>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default Comm;
