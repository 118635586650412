import React, { Component } from 'react';

import s from './SearchInput.module.scss';

class SearchInput extends Component
{
    render()
    {
        return (
            <>
                <input className={`${s.input} form-control`} required={this.props.required} list={this.props.id} placeholder={this.props.placeholder} />
                <datalist id={this.props.id}>
                    {
                        this.props.Options.map((value) =>
                            <option value={value.option} key={value.key} />

                        )
                    }
                </datalist>
            </>
        );
    }
}

export default SearchInput;