import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

import s from './LockCenter.module.scss';

class ChartOne extends Component
{
    render()
    {
        return (
            <div className={s.chartOne}>
                <p>% of donors in 100000 (p)</p>
                <Doughnut
                    data={{
                        labels: ["A", "B", "AB", "O", "AB+"],
                        datasets: [
                            {
                                label: ["National Blod Pourcentage"],
                                data: ["40", "30", "20", "4", "6"],
                                backgroundColor: ["#F3FEB0", "#FEA443", "#705E78", "#A5AAA3", "#812F33"],
                                borderColor: "#bac8d9",
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

export default ChartOne;