import react from "react";
import { Card } from "react-bootstrap";

function Post(props) {
  return (
    <Card className="Mycard customPostCard">
      <p>{props.topic}</p>
    </Card>
  );
}

export default Post;
