import React from 'react'
import { Link } from 'react-router-dom'
import Select from '../../../../GlobaleComponents/Select/Select'
import { RegionData } from './Data/RegionData'
import s from '../Appointment.module.scss';

export function StateRegion() {
    return (
        <div className={`${s.card} position-absolute top-50 start-50 translate-middle card`}>
            <h5 className={`${s.cardHeader} card-header`}>To make an appointment You must follow these steps.</h5>
            <hr className={s.hr}/>
            <div className={`${s.cardBody} card-body`}>
                <h5 className={`${s.cardTitle} card-title`}>First Step:: Select your State/Region</h5>

                <Select items={RegionData} selectBackColor={{ backgroundColor: "#eaf6f6" }}/>

                <Link to="/appointment/stateregion/city" className={`${s.btn} btn`}>Next Step</Link>
            </div>
        </div>
    )
}