import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';

import style from './Storage.module.scss';

export default function Storage()
{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div className={`${style.storage} container`}>
            <h5 className={style.title}>Add Storage of Blood Bank</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={`${style.form}`}>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" pattern="^\d*(\.\d{0,4})?$" placeholder="storage (%)" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add storage (%).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" pattern="^\d*(\.\d{0,4})?$" placeholder="duration" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a duration.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" pattern="^\d*(\.\d{0,4})?$" placeholder="min of bank (%)" min='0' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a min of bank (%).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control as="textarea" rows={3} placeholder="description..." maxLength='12' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a frozen plasma quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className={style.btnSubmit}>
                    Add Blood Bank
                </Button>
            </Form>
        </div>
    );
}