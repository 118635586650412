import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  '@media (max-width: 991px)': {
    inputSearch: {
      display: 'flex',
      marginBottom: '20px',
    },
    iconSearch: {
      padding: '10px',
      borderRadius: '3px',
    },
    tableProfile: {
      marginBottom: '50px',
    },
  },
  tableProfile: {
    marginBottom: '30px',
  },
  inputSearch: {
    display: 'flex',
    marginBottom: '30px',
  },
  iconSearch: {
    padding: '16px',
    backgroundColor: '#fff',
    border: '1px solid #bac8d9',
    borderRadius: '5px',
    color: '#bac8d9',
    cursor: 'pointer'
  },
  root: {

    '& .MuiDataGrid-main': {
      borderRadius: '5px',
      borderColor: 'red'
    },

    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: '#32aeb8',
      textAlign: 'center',
      color: '#ffffff',
    },

    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      borderLeft: '1px solid #bac8d9',
      borderTop: 'none',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell--withRenderer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      flexWrap: 'nowrap',
      flexDirection: 'column',
    },
  },
  btnPrimary: {
    backgroundColor: '#32aeb8',
    color: '#fff',
    fontWeight: 'bold',
    border: '1px solid #bac8d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      color: '#32aeb8',
      border: '1px solid #bac8d9',
    },
  },
});