import react, { Component } from "react";
import { Card, Row, Col, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Bar } from 'react-chartjs-2';

function Bloodst(props) {
  return (
    <Card className="Mycard customCardBloodst">
      <p style={{textAlign:"center"}}>% of fullness in the centers</p>
      <Bar
            data={{
              labels: ["1", "2", "3", "4", "5", "6", "7", "8","9","10","11","12","13","14","15","16","17","18"],
              datasets: [
                {
                  label: ["percentage of fullness in the centers"],
                  data: ["70", "60", "55", "65", "75", "62", "72", "79","45","43","51","67","47","45","66","55","48","71"],
                  backgroundColor: ["#38003D", "#F01973", "#983DB2", "#FB4F47"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
      {/* <Row>
        <Col lg={3}></Col>
        <Col lg={9}>
          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <div className="customProgress">
              <ProgressBar now={60} />
            </div>
          </div>

          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <di className="customProgress">
              <ProgressBar now={60} />
            </di>
          </div>

          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <di className="customProgress">
              <ProgressBar now={60} />
            </di>
          </div>

          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <di className="customProgress">
              <ProgressBar now={60} />
            </di>
          </div>

          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <di className="customProgress">
              <ProgressBar now={60} />
            </di>
          </div>

          <div className="customParent">
            <div className="costumIconBloodst">
              <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
            </div>
            <di className="customProgress">
              <ProgressBar now={60} />
            </di>
          </div>
        </Col> 
      </Row>*/}
    </Card>
  );
}

export default Bloodst;
