import { Component } from "react";
import { Formik, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";

export const AddCenterForm = () => {
  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        ref: "",
        name: "",
        phone: "",
        state: "",
        city: "",
        adress: "",
        capacity: "",
        duration: "",
      },
      validationSchema: Yup.object({
        ref: Yup.string()
          .trim()
          .required("Required")
          .max(100, "the reference shouldn't contain more than 100 character"),
        name: Yup.string()
          .required("Required")
          .trim()
          .max(100, "the name shouldn't contain more than 100 character"),
        phone: Yup.string()
          .matches(/^((\+[0-9]{2}[0-9]?)|0)[0-9]{9}$/, "phone invalid")
          .required("Required"),
        adress: Yup.string()
          .required("Required")
          .trim()
          .max(250, "the adress shouldn't contain more than 250 character"),
        capacity: Yup.number("capacity should be a number").required(
          "Required"
        ),
        duration: Yup.number("duration should be a number").required(
          "Required"
        ),
      }),
      onSubmit: (values) => {
        console.log(values);
      },
    });

  return (
    <div className="addCenterForm Mycard">
      <h5 className="addCenterForm-header">Fill the informations.</h5>
      <hr />
      <div className="addCenterForm-body">
        <Formik>
          <Form onSubmit={handleSubmit}>
            {/* reference zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="ref"
                placeholder="Reference"
                onBlur={handleBlur}
                value={values.ref}
                onChange={handleChange}
                className={
                  touched.ref && errors.ref
                    ? "is-invalid"
                    : touched.ref
                    ? "is-valid"
                    : ""
                }
              />

              {touched.ref && errors.ref ? (
                <div class="invalid-feedback d-block">{errors.ref}</div>
              ) : touched.ref ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* name zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                onBlur={handleBlur}
                value={values.name}
                onChange={handleChange}
                className={
                  touched.name && errors.name
                    ? "is-invalid"
                    : touched.name
                    ? "is-valid"
                    : ""
                }
              />
              {touched.name && errors.name ? (
                <div class="invalid-feedback d-block">{errors.name}</div>
              ) : touched.name ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* phone zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="phone"
                placeholder="phone"
                onBlur={handleBlur}
                value={values.phone}
                onChange={handleChange}
                className={
                  touched.phone && errors.phone
                    ? "is-invalid"
                    : touched.phone
                    ? "is-valid"
                    : ""
                }
              />
              {touched.phone && errors.phone ? (
                <div class="invalid-feedback d-block">{errors.phone}</div>
              ) : touched.phone ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* state select */}

            <Form.Group>
              <Field
                component="select"
                className="form-select"
                name="state"
                onBlur={handleBlur}
                value={values.state}
                onChange={handleChange}
              >
                <option value="state1">state 1</option>
                <option value="state2">state 2</option>
                <option value="state3">state 3</option>
                <option value="state4">state 4</option>
              </Field>
            </Form.Group>

            {/* city select */}

            <Form.Group>
              <Field
                component="select"
                className="form-select"
                name="city"
                onBlur={handleBlur}
                value={values.city}
                onChange={handleChange}
              >
                <option value="tetoun">tetoun</option>
                <option value="tanger">tanger</option>
                <option value="rabat">rabat</option>
                <option value="chaouen">chaouen</option>
              </Field>
            </Form.Group>

            {/* adress zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="adress"
                placeholder="adress"
                onBlur={handleBlur}
                value={values.adress}
                onChange={handleChange}
                className={
                  touched.adress && errors.adress
                    ? "is-invalid"
                    : touched.adress
                    ? "is-valid"
                    : ""
                }
              />

              {touched.adress && errors.adress ? (
                <div class="invalid-feedback d-block">{errors.adress}</div>
              ) : touched.adress ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* capacity zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="capacity"
                placeholder="capacity"
                onBlur={handleBlur}
                value={values.capacity}
                onChange={handleChange}
                className={
                  touched.capacity && errors.capacity
                    ? "is-invalid"
                    : touched.capacity
                    ? "is-valid"
                    : ""
                }
              />
              {touched.capacity && errors.capacity ? (
                <div class="invalid-feedback d-block">{errors.capacity}</div>
              ) : touched.capacity ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            {/* duration zone */}
            <Form.Group controlId="validationFormik01">
              <Form.Control
                type="text"
                name="duration"
                placeholder="duration"
                onBlur={handleBlur}
                value={values.duration}
                onChange={handleChange}
                className={
                  touched.duration && errors.duration
                    ? "is-invalid"
                    : touched.duration
                    ? "is-valid"
                    : ""
                }
              />
              {touched.duration && errors.duration ? (
                <div class="invalid-feedback d-block">{errors.duration}</div>
              ) : touched.duration ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>

            <Button type="submit" className="btnAdd">
              Add
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
