import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from '../../../../GlobaleComponents/Select/Select'
import { AvatarData } from './Data/AvatarData'

import style from './Register.module.scss';

class Register extends Component
{
    render()
    {
        return (
            <div className={style.register}>
                <div className={`${style.card} card position-absolute top-50 start-50 translate-middle`}>
                    <div className={`${style.cardHeader} card-header text-center`}>Create Team</div>
                    <hr className={style.hr}/>
                    <div className="card-body">
                        <form className={style.form}>
                            <label htmlFor='lbl_name'>Team Name (<code><sup>*</sup></code> required field)</label>
                            <input className={style.lblName} type='text' id='lbl_name' name='lbl_name' placeholder='team name' />
                            <label htmlFor='avatar'>Choose Avatar for Team</label>
                            <Select items={AvatarData} id='avatar' selectBackColor={{ backgroundColor: "#eaf6f6" }}/>
                            <label htmlFor="discription">Description</label>
                            <textarea className={style.textArea} id="discription" name="discription" rows="4" cols="50" placeholder='describe your team' />
                        </form>
                        <Link to="/team/main/posts" className={`${style.btnRegister} btn`}>Create Team</Link>
                        <Link to="/home" className={`${style.btnCancel} btn`}>Cancel</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;