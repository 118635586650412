import React, { Component } from "react";
import { Navbar, Form, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../../logo.svg";
import { IoGlobeOutline } from "react-icons/io5";

class NavbarLogin extends Component {
  render() {
    return (
      <Navbar className="navbar-login"  expand="lg">
        <Link to="/">
          <img src={logo} className="App-logo-login" alt="logo" />
        </Link>
          <Navbar.Brand className="nv-brand">DoniteLife</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="collapse">
        <Form inline className="nav-form-login">
          <NavDropdown title={<IoGlobeOutline />} id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.3">English</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.1">Français</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Arabic</NavDropdown.Item>
          </NavDropdown>
          <Link
            to="/register"
            className="btn-create-account"
          >
            Create Account
          </Link>
        </Form>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavbarLogin;
