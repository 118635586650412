import react, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import Search from "../../Search/search";
import AddNew from "../../AddNewBtn/AddNew";
import DeriCard from "./DerictorCard/deriCard";

class Derictors extends Component {
  state = {
    derictors: [
      {
        img: "",
        name: "Name : Mouad benchkroun",
        adress: "Adress : boujarah ,Tetouan",
        param1: "Telephone : 0661435465",
        param2: "E-mail : mouadbenchakroun@gmail.com",
        param3: "Number of Employee : P212345654",
      },
      {
        img: "",
        name: "Name : karima ELKhayti",
        adress: "Adress : masnana ,Tanger",
        param1: "Telephone : 0687654378",
        param2: "E-mail : karimaelkhayati@gmail.com",
        param3: "Number of Employee : D345672346",
      },
      {
        img: "",
        name: "Name : Mohamed Aziz",
        adress: "Adress : maarif ,CasaBlanca",
        param1: "Telephone : 0656787453",
        param2: "E-mail : mohamedazizi@gmail.com",
        param3: "Number of Employee : E3456413345",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="search">
          <div>
            <div class="mainSearch">
              <Row>
                <Col
                  xs={{ order: 2 }}
                  sm={{ order: 2 }}
                  md={{ span: 8, order: 1 }}
                  className="colsearch"
                >
                  <Search place="search a derictor"></Search>
                </Col>

                <Col
                  sm={{ span: 5, offset: 7, order: 1 }}
                  xs={{ span: 5, offset: 7, order: 1 }}
                  md={{ span: 3, offset: 1, order: 2 }}
                  className="colButton"
                >
                  <AddNew
                    path="/directorgenerale/management/derictors/add-derictor"
                    icon={faUserPlus}
                    text="Add derictor"
                  ></AddNew>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ul className="ulCards">
          {this.state.derictors.map((item, index) => (
            <li>
              <DeriCard
                name={item.name}
                adress={item.adress}
                param1={item.param1}
                param2={item.param2}
                param3={item.param3}
              ></DeriCard>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Derictors;
