import React, { Component } from 'react';
import { FaSistrix } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import s from './NavSearchBar.module.scss';

class NavSearchBar extends Component
{
    render()
    {
        return (
            <form className={`${s.dFlex} d-flex`}>
                <input className={`${s.formControl} form-control`} list="datalistOptions" id="exampleDataList" placeholder="type username to search..." aria-label="Username"/>
                    <datalist id="datalistOptions">
                    </datalist>
                <Link className="btn" to='/username'><FaSistrix /></Link>
            </form>
        );
    };
};

export default NavSearchBar;