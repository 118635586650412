import Switch from '@material-ui/core/Switch';
import React, { Component } from 'react';

import s from './Setting.module.scss';

import { username } from "react-lorem-ipsum";

import { Link } from 'react-router-dom';

// Translation Higher Order Component
import
{
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
} from 'react-switch-lang';

import en from './Data/en.json';
import ar from './Data/ar.json';
import fr from './Data/fr.json';
import PropTypes from 'prop-types';


// Do this two lines only when setting up the application
setTranslations({ en, ar, fr });
setDefaultLanguage('en');

// If you want to remember selected language
setLanguageCookie();

class Setting extends Component
{
    handleSetLanguage = (key) => () =>
    {
        setLanguage(key);
    };

    handleChange = (event) =>
    {
        this.setState({ event, [event.target.name]: event.target.checked });
    };

    handleSelectLanguage(event)
    {
        this.setState({ value: event.target.value });
        setDefaultLanguage(event.target.value);
    }
    constructor(props)
    {
        super(props);
        this.state = {
            checkedA: false,
            value: '',
        };

        this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
    }

    render()
    {
        const { t } = this.props;
        return (
            <div className={s.setting} dir={this.state.value === 'ar' ? 'rtl' : 'ltr'}>

                {/* {t('home.title')}
                {t('home.title', null, 'ar')}
                {t('hello', { name: 'Mohamed' })}
                {t('fallback')} */}

                <div className={`${s.card} position-absolute top-50 start-50 translate-middle card`}>
                    <div className={`${s.cardBody} card-body`}>
                        <div className={s.username}>
                            <p>{t('setting.username')} <span style={this.state.value === 'ar' ? { float: 'left' } : { float: 'right' }}>{username()}</span></p>
                        </div>
                        <hr />
                        <div className={s.email}>
                            <p>{t('setting.email')} <span style={this.state.value === 'ar' ? { float: 'left' } : { float: 'right' }}>xyz@email.com</span></p>
                        </div>
                        <hr />
                        <div className={s.dark}>
                            <p>{t('setting.darkMode')}</p>
                            <Switch
                                className={s.btnSwitch}
                                checked={this.state.checkedA}
                                onChange={this.handleChange}
                                color="primary"
                                name="checkedA"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        <br /> <hr />
                        <div className={s.language}>
                            <p>{t('setting.language')} </p>

                            <select onChange={this.handleSelectLanguage} defaultChecked={this.state.value} className={`form-select ${s.formSelect}`} aria-label="Default select example">
                                <option defaultValue className={s.option} value='en'>English</option>
                                <option className={s.option} value='ar'>Arabic</option>
                                <option className={s.option} value='fr'>Franch</option>
                            </select>

                        </div>
                        <hr />
                        <div>
                            <p>
                                <Link
                                    type='button'
                                    className={s.edit}
                                    style={this.state.value === 'ar' ? { float: 'left' } : { float: 'right' }}
                                    to='/profile/userinfo'>{t('setting.editProfile')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

Setting.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Setting);