import LoremIpsum, { username } from "react-lorem-ipsum";

export const PostData =
    [
        {
            userpost: username(),
            description: "On Friday, September 15, 2017, the Minister of Health, Professor Al-Hussein Al-Wardi, inaugurated the new regional center for blood transfusion in Marrakech, which was built on an area of 3,677 square meters, and required a budget for construction, studies and modern equipment of machines and biomedical equipment approximately 26 million dirhams.",
            likes: "kar_ma, y_hamouda and 3 person",
            comments: [
                {
                    username: username(),
                    comment: "Thanks for the information."
                },
                {
                    username: username(),
                    comment: "God willing, we will go."
                },
                {
                    username: username(),
                    comment: "That's great news I'm hearing today."
                },
            ]
        },
        {
            userpost: username(),
            description: "On the occasion of this inauguration, the Minister handed over 6 ambulances equipped with biomedical mechanisms and 10 mobile medical units for distribution to rural health centers, giving priority to the remote areas of the Marrakesh-Safi region. Thus, patients and injured people in these areas will be able to obtain emergency health transportation in safe and secure conditions, and benefit from health services through field visits to mobile medical units.",
            likes: "",
            comments: [
                {
                    username: username(),
                    comment: "♥♥♥"
                },
                {
                    username: username(),
                    comment: "☻☻☻"
                },
            ]
        },
        {
            userpost: username(),
            description: "The Minister recently launched the services of the mental and psychiatric hospital in the city of Kelaat Sraghna, which was built with high-quality engineering specifications on an area of 30,000 square meters, with a financial envelope of 56 million dirhams, with a capacity of 120 beds.",
            likes: "kar_ma and 3 person",
            comments: [
                {
                    username: username(),
                    comment: "thank you"
                }
            ]
        },
    ];