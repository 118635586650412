export const TabsParametersData =
    [
        {
            label: 'Add',
            link: '/parameters/add'
        },
        {
            label: 'Remove',
            link: '/parameters/remove'
        },
        {
            label: 'Request',
            link: '/parameters/request'
        },
    ]