import React, { Component } from "react";
import { Navbar, Form, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoGlobeOutline } from "react-icons/io5";
import logo from "../../../../logo.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }
  render() {
    return (
      <div className="homePageHeader"  ref={this.wrapper}>
        {/* {this.props.children} */}
        <Navbar className="navbar" expand="lg">
          <img src={logo} className="App-logo" alt="logo" />
          <Navbar.Brand href="#home" className="nv-brand">
            DonateLife
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="collapse">
            <Form inline className="frmlog">
              <NavDropdown title={<IoGlobeOutline />} id="basic-nav-dropdown">
                <NavDropdown.Item>English</NavDropdown.Item>
                <NavDropdown.Item>Français</NavDropdown.Item>
                <NavDropdown.Item>Arabic</NavDropdown.Item>
              </NavDropdown>
              <Link to="/login" className="btn">
                Login
              </Link>
              <Link to="/register" className="btncreate">
                Create Account
              </Link>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
