import { Avatar, fullname, name, surname, username } from 'react-lorem-ipsum';

export const MembersData = [
    {
        profile: <Avatar gender="male" className='avatar' alt="Avatar" />,
        username: username(),
        name: name('male'),
        surname: surname(),
        nickName: fullname('female'),
        birthday: '12/12/2012',
        state: 'Tanger/1990',
        city: 'Fnideq'
    },
    {
        profile: <Avatar gender="female" className='avatar' alt="Avatar" />,
        username: username(),
        name: name('female'),
        surname: surname(),
        nickName: fullname('male'),
        birthday: '12/12/2003',
        state: 'Tanger/Tetouan',
        city: 'martil'
    },
    {
        profile: <Avatar gender="male" className='avatar' alt="Avatar" />,
        username: username(),
        name: name('male'),
        surname: surname(),
        nickName: fullname('female'),
        birthday: '12/01/1999',
        state: 'Tanger/Tetouan',
        city: 'Tetouan'
    },
    {
        profile: <Avatar gender="female" className='avatar' alt="Avatar" />,
        username: username(),
        name: name('female'),
        surname: surname(),
        nickName: fullname('male'),
        birthday: '03/01/1994',
        state: 'Tanger/Tetouan',
        city: 'Chefchaouen'
    },
    {
        profile: <Avatar gender="male" className='avatar' alt="Avatar" />,
        username: username(),
        name: name('male'),
        surname: surname(),
        nickName: fullname('female'),
        birthday: '03/01/1995',
        state: 'Tanger/Tetouan',
        city: 'Mdiq'
    },
];