import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { FaCopy, FaFacebook, FaUserPlus, FaWhatsapp } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';

import s from './Modale.module.scss';

function Modale(props)
{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <>
            <Button
                style={props.buttonStyle}
                variant="primary"
                onClick={handleShow}>
                {props.buttonText}
                <FaUserPlus />
            </Button>

            <Modal className={s.modaleForm} size='lg' aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header className={s.modaleHeader}>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body className={s.formBody}>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address Or User Name</Form.Label>
                            <Form.Control type="text" placeholder="name@example.com or @username" required />
                            <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer className={s.formFooter}>
                        <Button className={s.formCancel} variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className={s.formSubmit} variant="primary" type="submit">
                            Send Request
                        </Button>
                    </Modal.Footer>
                </Form>

                <Modal.Body>
                    <hr />
                    <Modal.Header className={s.modaleShare}>
                        <Button className={s.btn} title='copy url invitaion'><FaCopy /></Button>
                        <Button className={s.btn} title='share on facebook'><FaFacebook /></Button>
                        <Button className={s.btn} title='share on whatsapp'><FaWhatsapp /></Button>
                        <Button className={s.btn} title='send url email'><IoMail /></Button>
                    </Modal.Header>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Modale;