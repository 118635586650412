import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";

import react, { Component } from "react";
import FilterCard from "./FilterCard/filterCard";

class Filter extends Component {
  state = {
    informations: [
      {
        text: "Request about the new campaign",
        date: "02-14-2021 20:20",
      },
      {
        text: "new instructions from the ministery",
        date: "04-14-2021 20:20",
      },
      {
        text: "World Blood Donor Day",
        date: "06-14-2021 20:20",
      },
    ],
  };
  render() {
    return (
      <div className={"filterContainer fluid " + this.props.className}>
        <div>
          <h5>
            filter <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
          </h5>
          <div className="divIconFilter">
            <IoCloseCircleSharp onClick={this.props.onClick} />
          </div>
        </div>

        <ul className="ulCards cr">
          {this.state.informations.map((item, index) => (
            <li>
              <FilterCard text={item.text} date={item.date}></FilterCard>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Filter;
