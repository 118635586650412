import Add from "../Add/Add";
import Remove from "../Remove/Remove";
import Request from "../Request/Request";

export const PagesRouteParametersData =
    [
        {
            page: <Add />,
            link: '/parameters/add'
        },
        {
            page: <Remove />,
            link: '/parameters/remove'
        },
        {
            page: <Request />,
            link: '/parameters/request'
        },
    ]