import React, { useState, useEffect } from "react";
import moment from "moment";
import Header from "./header";
import "./Calendar.scss";

export default function Calendar({ value, onChange }) {
    const [calendar, setCalendar] = useState([]);

    useEffect(() => {
        setCalendar(buildCalendar(value));
    }, [value]);

    function buildCalendar(date) {
        const a = [];

        const startDay = date.clone().startOf("month").startOf("week");
        const endDay = date.clone().endOf("month").endOf("week");

        const _date = startDay.clone().subtract(0, "day");

        while (_date.isBefore(endDay, "day")) {
            a.push(
                Array(7)
                    .fill(0)
                    .map(() => _date.add(1, "day").clone())
            );
        }
        return a;
    }

    function isSelected(day) {
        return value.isSame(day, "day");
    }

    function beforeToday(day) {
        return moment(day).isBefore(new Date(), "day");
    }

    function isToday(day) {
        return moment(new Date()).isSame(day, "day");
    }

    function dayStyles(day) {
        if (beforeToday(day)) return "before";
        if (isSelected(day)) return "selected";
        if (isToday(day)) return "today";
        return "";
    }

    //function currMonthName() {
    //    return value.format("MMMM");
    //}

    //function currYear() {
    //    return value.format("YYYY");
    //}

    return (
        <div className="calendar">
            <Header value={value} onChange={onChange} />

            <div className="body">
                <hr/>
                <div className="day-names">
                    {
                        ["mo", "tu", "we", "th", "fr", "sa", "su"].map((d, id) =>
                        (
                            <div className="week" key={id}>{d}</div>
                        ))
                    }
                </div>

                {
                    calendar.map((week, wi) => (
                        <div key={wi} className='days-row'>
                            {
                                week.map((day, di) => (
                                    <div key={di} className="day" type='button' onClick={() => {
                                        if (day < moment(new Date()).startOf("day"))
                                            return;
                                        onChange(day);
                                    }} >
                                        <div className={dayStyles(day)}>
                                            {day.format("D").toString()}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>
        </div>
    );
}
