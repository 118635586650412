import { username } from "react-lorem-ipsum";

export const ProfileSearchData =
    [
        {
            option: username(),
            key: '1'
        },
        {
            option: username(),
            key: '2'
        },
        {
            option: username(),
            key: '3'
        },
        {
            option: username(),
            key: '4'
        },
        {
            option: username(),
            key: '5'
        },
        {
            option: username(),
            key: '6'
        },
        {
            option: username(),
            key: '7'
        },
        {
            option: username(),
            key: '8'
        },
        {
            option: username(),
            key: '9'
        },
        {
            option: username(),
            key: '10'
        },
        {
            option: username(),
            key: '11'
        },
        {
            option: username(),
            key: '12'
        },
        {
            option: username(),
            key: '13'
        },
        {
            option: username(),
            key: '14'
        },
        {
            option: username(),
            key: '15'
        },
        {
            option: username(),
            key: '16'
        },
        {
            option: username(),
            key: '17'
        },
    ];