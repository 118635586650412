import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import s from './LockCenter.module.scss';

import LoremIpsum from 'react-lorem-ipsum';
import { Link } from 'react-router-dom';
import { IoCalendarSharp } from 'react-icons/io5';

import SearchInput from '../../../GlobaleComponents/AutoCompletSelectSearch/SearchInput';
import { HospitalCentersLockCenterData } from './Data/HospitalCentersLockCenterData';

import ChartOne from './ChartOne';
import ChartTwo from './ChartTwo';
import ChartThree from './ChartThree';
import { FaSearch } from 'react-icons/fa';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class LockCenter extends Component
{
    static defaultProps = {
        key: 'AIzaSyAZv5rqs3qYVEZF7p-EcXEJZgmdI_UedkA',
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render()
    {
        return (
            <div className={`${s.lockCenter} container-fuild`}>
                <div className={s.inputSearch}>
                    <SearchInput Options={HospitalCentersLockCenterData} placeholder='search center' id='lockCenterCity' />
                    <i className={s.iconSearch}>
                        <FaSearch />
                    </i>
                </div>
                <Link type='button' to='/appointment/stateregion' className={`${s.btnRight} btn`}>
                    <span>Fix Appointment</span>
                    <i className={s.icon}><IoCalendarSharp /></i>
                </Link>
                <div className={s.map}>
                    <GoogleMapReact
                        bootstrapURLKeys={this.props.key}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    // yesIWantToUseGoogleMapApiInternals={true}
                    // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        <AnyReactComponent
                            lat={this.props.lat}
                            lng={this.props.lng}
                            text="Lock a Center"
                        />
                    </GoogleMapReact>
                </div>

                <div className={s.descriptionMap1}>
                    <span>Centre national de transfusion Sanguine</span><br/>
                    <span>Address: Rabat</span><br />
                    <span > work hours:</span ><br />
                    <ul>
                        <li>Friday 8 AM-5:30 PM</li>
                        <li>Saturday 8:30 AM - 2 PM</li>
                        <li>Sunday is closed</li>
                        <li>Monday 8am-5:30 PM</li>
                        <li>Tuesday 8am-5:30 PM</li>
                        <li>Wednesday 8 AM-5:30 PM</li>
                        <li>Thursday 8am-5:30 PM</li>
                    </ul>
                    <span>Closed ⋅ Will open at 8:30 AM Saturday</span><br />
                    <span > Phone: 05377 - 74993</span >
                </div>

                <div className={s.charts}>
                    <ChartOne />
                    <ChartTwo />
                    <ChartThree />
                </div>
            </div>
        )
    }
}
export default LockCenter;