import { Avatar, fullname, name, surname, username } from 'react-lorem-ipsum';

export const EmployeeData = [
    {
        image: <Avatar gender="male" alt="Avatar" />,
        username: username(),
        cin: 'LF546QSW',
        ppr: 'WQ646AS56',
        name: name('male'),
        surname: surname(),
        jobTitle: fullname('female'),
        birthday: '12/12/2012',
        email: 'xyz@email.com',
        phoneNumber: '+21265898959',
        hospital: 'Saniat Rmel',
        state: 'Tanger/1990',
        city: 'Fnideq'
    },
    {
        image: <Avatar gender="female" alt="Avatar" />,
        username: username(),
        cin: 'LF54654',
        ppr: 'WQ646AS56',
        name: name('female'),
        surname: surname(),
        jobTitle: fullname('male'),
        birthday: '12/12/2003',
        email: 'xyz@email.com',
        phoneNumber: '+21265898959',
        hospital: 'Saniat Rmel',
        state: 'Tanger/Tetouan',
        city: 'martil'
    },
    {
        image: <Avatar gender="male" alt="Avatar" />,
        username: username(),
        cin: 'QW126QSW',
        ppr: 'WQ646AS56',
        name: name('male'),
        surname: surname(),
        jobTitle: fullname('female'),
        birthday: '12/01/1999',
        email: 'xyz@email.com',
        phoneNumber: '+21265898959',
        hospital: 'Saniat Rmel',
        state: 'Tanger/Tetouan',
        city: 'Tetouan'
    },
    {
        image: <Avatar gender="female" alt="Avatar" />,
        username: username(),
        cin: 'CF546QSW',
        ppr: 'WQ646AS56',
        name: name('female'),
        surname: surname(),
        jobTitle: fullname('male'),
        birthday: '03/01/1994',
        email: 'xyz@email.com',
        phoneNumber: '+21265898959',
        hospital: 'Saniat Rmel',
        state: 'Tanger/Tetouan',
        city: 'Chefchaouen'
    },
    {
        image: <Avatar gender="male" alt="Avatar" />,
        username: username(),
        cin: 'GH476QSW',
        ppr: 'WQ646AS56',
        name: name('male'),
        surname: surname(),
        jobTitle: fullname('female'),
        birthday: '03/01/1995',
        email: 'xyz@email.com',
        phoneNumber: '+21265898959',
        hospital: 'Saniat Rmel',
        state: 'Tanger/Tetouan',
        city: 'Mdiq'
    },
];