import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FormInput() {
  // const history = useHistory();
  // const handelLogin = () => {
  //   history.push('/donor');
  // }
  return (
    <Form className="form-input-login">
      <h2>Sign in</h2>
      <Form.Group controlId="formGroupUserName">
        <Form.Label>User name :</Form.Label>
        <Form.Control type="text" placeholder="User name" />
      </Form.Group>
      <Form.Group controlId="formGroupPassword">
        <Form.Label>Password :</Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group controlId="formGroupCheck">
        <Form.Check type="checkbox" label="Remember me" />
      </Form.Group>
      <Link to="/donor">
      <Button type="submit" className="btn-forminput-login">
        Login
      </Button>
      </Link>
      <hr></hr>
      <div className="div-footer-login">
        <div className="div-info-login">
          <label className="lbl-info-login">
            if you haven't an account on DoniteLife
          </label>
          <Link to="/register">
            <Button type="button" className="btn-forminput-register">
              Register
            </Button>
          </Link>
        </div>
      </div>
    </Form>
  );
}
