import React from 'react';
import { Link } from 'react-router-dom';
import { HiBadgeCheck } from 'react-icons/hi';

import s from './Succes.module.scss'
import sCard from '../Appointment.module.scss';

export function Succes()
{
    return (
        <div className={s.success}>
            <div className={`${sCard.card} position-absolute top-50 start-50 translate-middle card`}>
                <h5 className={`${sCard.cardHeader} card-header`}>The Appointment has successfully created <HiBadgeCheck /></h5>
                <hr />
                <div className={`${sCard.cardBody} card-body`}>
                    <div className={s.succesBody}>
                        <div className={s.succes}>
                            <div className={s.succesInfo}>
                                <span className={s.left}>State/Region</span>
                                <span className={s.right}>Tanger/Tetouan</span>
                            </div>
                            <hr className={s.hr}/>
                            <div className={s.succesInfo}>
                                <span className={s.left}>City</span>
                                <span className={s.right}>Tetouan</span>
                            </div>
                            <hr className={s.hr} />
                            <div className={s.succesInfo}>
                                <span className={s.left}>Center</span>
                                <span className={s.right}>Blood injection center</span>
                            </div>
                            <hr className={s.hr} />
                        </div>
                        <div className={s.succesBox}>
                            <p>02 - 14 - 2021</p>
                            <p>14:00</p>
                        </div>
                    </div>
                    <Link to="/appointment/stateregion/city/center/datetime" className={`${sCard.btn} btn`}>Edit</Link>
                    <Link to="/remove" className={`${sCard.btn} ${sCard.previous} btn`}>Remove</Link>
                </div>
            </div>
        </div>
    )
}