import "./App.css";
import TableProfile from "./TableProfile/TableProfile";
import Formone from "./Form/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Details from "./Details/Details";
import NavBarUser from "../GlobaleComponents/NavBarUser/NavBarUser";
import SideMenuBar from "../GlobaleComponents/SideMenuBarUser/SideMenuBar";
import "../DirectorGenerale/componenets/NavBarUser/NavBarUser.scss";
import { Calendary } from "./Calendar/Calendar";
import { Share } from "../Settings/Share/Share";
import Setting from "../Settings/Setting/Setting";
import { TermConditions } from "../Settings/Info/TermConditions";
import { PrivacyPolicy } from "../Settings/Info/PrivacyPolicy";
import { NotificationData } from "../Donor/Components/Data/NotificationData";
import * as icon from "react-icons/fa";
import { IoCalendarSharp } from "react-icons/io5";
import { MdPersonAdd } from "react-icons/md";

function App()
{
  const SideBarData = [
    {
      title: "Home",
      link: "/receptionist",
      icon: <icon.FaHome />,
      Name: "menuItemsIconText",
    },
    {
      title: "Profile",
      link: "/profile",
      icon: <icon.FaUserCircle />,
      Name: "menuItemsIconText",
    },
    {
      title: "Appointment",
      link: "/calendar",
      icon: <IoCalendarSharp />,
      Name: "menuItemsIconText",
    },
    {
      title: "Add Donors",
      link: "/adding",
      icon: <MdPersonAdd />,
      Name: "menuItemsIconText",
    },
  ];

  return (
    <div className="App">
      <Router>
        <NavBarUser NotifData={NotificationData} />
        <SideMenuBar sideData={SideBarData} />
        <Switch>
          <Route path="/receptionist" component={TableProfile} />
          <Route path="/profile" component={Details} />
          <Route path="/calendar" component={Calendary} />
          <Route path="/adding" component={Formone} />
          <Route path="/share" component={Share} />
          <Route path="/setting" component={Setting} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/terms" component={TermConditions} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
