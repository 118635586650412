import React from 'react';
import { Link } from 'react-router-dom';
import Select from '../../../../GlobaleComponents/Select/Select';
import { CityData } from './Data/CityData';

import s from '../Appointment.module.scss';

export function City() {
    return (
        <div className={`${s.card} position-absolute top-50 start-50 translate-middle card`}>
            <h5 className={`${s.cardHeader} card-header`}>To make an appointment You must follow these steps.</h5>
            <hr />
            <div className={`${s.cardBody} card-body`}>
                <h5 className={`${s.cardTitle} card-title`}>Second Step:: Select your City</h5>

                <Select items={CityData} selectBackColor={{ backgroundColor: "#eaf6f6" }}/>

                <Link to="/appointment/stateregion/city/center" className={`${s.btn} btn`}>Next Step</Link>
                <Link to="/appointment/stateregion" className={`${s.previous} ${s.btn} btn`}>Previous</Link>
            </div>
        </div>
    )
}