import React, { Component } from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import "./footer.scss";
import logo from "../../../../logo.svg";

class Footer extends Component {
  render() {
    return (
      <div className="div-footer">
        <div className="div-icons">
          <div>
            <FaFacebookSquare className="icons"/>
            <label>DoniteLife</label>
          </div>
          <div>
            <FaWhatsappSquare className="icons" />
            <label>+212600-000000</label>
          </div>
          <div>
            <FaInstagramSquare className="icons"/>
            <label className="lbl-icons">DoniteLife</label>
          </div>
        </div>
        <div className="div-copyright">
          <img src={logo} alt="logo" className="logo-svg" />
          <label>Copy right DoniteLife 2021</label> 
        </div>
        <div className="div-aboutUs">
          <label>Contact</label>
          <label>Our Community</label>
          <label>Documentation</label>
          <label>Guide</label>
          <label>Privacy-policy</label>
        </div>
      </div>
    );
  }
}

export default Footer;
