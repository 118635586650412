import { useState } from "react";

const useForm2 = (validate) => {
  const [values, setValues] = useState({
    cin: "",
    tel: "",
    confirmTel: "",
    selection:"",
  });

  const [errors, setErrors] = useState({});

  const handelchange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
  };
  return {handelchange, values,handelSubmit,errors};
};

export default useForm2;
