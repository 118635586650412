import * as icon from 'react-icons/fa';
import { IoCalendarSharp } from 'react-icons/io5';
import { RiTeamFill } from 'react-icons/ri';

export const SideMenuData = [
    {
        title: 'Home',
        link: '/donor',
        icon: <icon.FaHome />,
    },
    {
        title: 'Profile',
        link: '/profile/userinfo',
        icon: <icon.FaUserCircle />,
    },
    {
        title: 'Appointment',
        link: '/appointment/stateregion',
        icon: <IoCalendarSharp />,
    },
    {
        title: 'Team',
        link: '/team/register',
        icon: <RiTeamFill />,
    },
    {
        title: 'Center',
        link: '/lockcenter',
        icon: <icon.FaHospital />,
    }
];