import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { Row, Col } from "react-bootstrap";

class BloodInfo extends Component {
  render() {
    return (
      <Row className="blood-row">
        <Col lg={6}>
          <Line
          className="blood-bar"
            data={{
              labels: ["A+","A-","B+", "B-", "AB+", "AB-", "O+" ,"O-"],
              datasets: [
                {
                  label: "Blood Pourcentage",
                  data: ["5", "3", "4", "2","2","15","6","18"],
                  backgroundColor: ["#BFB4BE", "#80787F", "#FFEFFE", "#403C3F"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ], 
            }}
            width={300}
            height={150}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </Col>
        <Col lg={6}>
          <hr />
          <p>
          <h4>Number of emergency cases by blood type</h4>
          This graphic presents the number of emergency cases the center had a call for
          Making donations will decrease this number for sure.
          Help to make it 0 emergencies.
          </p>
        </Col>
      </Row>
    );
  }
}

export default BloodInfo;
