import React, { Component, useState } from 'react';
import { username } from 'react-lorem-ipsum';
import * as icon from 'react-icons/fa';
import { GeneratePostData } from '../Data/GeneratePostData';

import s from './GeneratePost.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

class GeneratePost extends Component
{
    render()
    {
        return (
            <>
                {
                    GeneratePostData.map((value, key) =>
                        <div className={`${s.card} card`} key={key}>
                            <div className={`${s.cardHeader} card-header`}>
                                <div className={s.btnOptionPosts}>
                                    <i className={s.notifyIcon} type='button' data-bs-toggle="dropdown" aria-expanded="false"><icon.FaEllipsisV /></i>
                                    <ul className={`${s.items} dropdown-menu`}>
                                        <li className={s.item}>Delete</li>
                                        <li className={s.item}>Edit</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${s.cardBodyText} card-body`}>
                                <div className={s.description}>{value.description}</div>
                                <div className={s.dateNotify}>{value.date}</div>
                            </div>
                        </div>
                    )}
            </>
        )
    }
}

export default GeneratePost;