import react, { Component } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
function AddNew(props) {
  return (
    <Button
      as={Link}
      to={props.path}
      variant="secondary"
      type="button"
      className={"btnAddNew " + props.className}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={props.icon} className="faIconAdd" />
      <p>{props.text}</p>
    </Button>
  );
}

export default AddNew;
