import React, { Component } from "react";
import * as icon from "react-icons/fa";

import { MembersData } from './Data/MembersData';

import s from './Members.module.scss';

class Member extends Component
{
  render()
  {
    return (
      <>
        {
          MembersData.map((value, key) =>

            <div className={`${s.card} card mb-3`} key={key}>
              <div className="row g-0">
                <div className={`${s.avatar} col-md-4`}>
                  <div className={s.memberUsername}>
                    {value.profile}
                    <br/><br/><p>User Name:: {value.username}</p>
                  </div>
                </div>
                <div className={`${s.memberInfo} col-md-8`}>
                  <div className={s.btnOptionMember}>
                    <i type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <icon.FaEllipsisV className={s.iconV}/>
                      <icon.FaEllipsisH className={s.iconH}/>
                    </i>
                    <ul className={`${s.items} dropdown-menu`}>
                      <li className={s.item}>Delete</li>
                      <li className={s.item}>Edit</li>
                    </ul>
                  </div>
                  <div className='card-body'>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                        Name:: {value.name}
                      </span>
                      <hr className={s.hr} />
                    </div>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                        Familly Name:: {value.surname}
                      </span>
                      <hr className={s.hr} />
                    </div>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                        Nick Name:: {value.nickName}
                      </span>
                      <hr className={s.hr} />
                    </div>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                       Birthday:: {value.birthday}
                      </span>
                      <hr className={s.hr} />
                    </div>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                        State:: {value.state}
                      </span>
                      <hr className={s.hr} />
                    </div>
                    <div className={s.cardBodyItems}>
                      <span className="card-text">
                        City:: {value.city}
                      </span>
                      <hr className={s.hr} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

export default Member;
