export default function validateinfo(values) {
  let errors = {};

  if (!values.cin) {
    errors.cin = "CIN is required";
  } else if (!/[A-Z]{1,2}[0-9]{1,8}/.test(values.cin)) {
    errors.cin = "CIN is invalide";
  }

  if (!values.tel) {
    errors.tel = "Phone number is required";
  } else if (!/[0-9]{6,12}/.test(values.tel)) {
    errors.tel = "Phone numbre is invalide";
  }
  if(values.selection === "+212"){
      errors.selection = "you must choose";
  }
  if (!values.confirmTel) {
    errors.confirmTel = "Confirmation is required";
  }

  return errors;
}
