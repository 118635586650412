import { FaCopy, FaFacebook, FaWhatsapp } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { Button, Modal } from 'react-bootstrap';
import { Alert, Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import React from 'react';

import s from './Share.module.scss';
import { Chip } from "@material-ui/core";
import { Component } from "react";

import { ShareUsersData } from "./ShareUsersData";

export class Share extends Component
{
    state = {
        showMessage: false
    };
    onButtonClickHandler = () =>
    {
        this.setState({ showMessage: !this.state.showMessage });
        navigator.clipboard.writeText('https://www.donatelife.com')
    };
    render()
    {
        return (
            <div className={`${s.share}`}>
                <div className={`${s.card} position-absolute top-50 start-50 translate-middle card`}>
                    <h5 className={`${s.cardHeader} card-header text-center`}>Copy, Send or share application URL with others to join us</h5>
                    <hr className={s.hr} />
                    <div className={`${s.cardBody} card-body`}>
                        <form>
                            <div className={s.formBody}>
                                <label htmlFor="exampleDataList" className="form-label">Search by user name or email addres, press enter to add multiple</label>

                                <Autocomplete
                                            multiple
                                            id="tags-filled"
                                    options={ShareUsersData.map((option) => option.username)}
                                            defaultValue={[]}
                                            freeSolo
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="default" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    label=""
                                                    margin="none"
                                                    variant="filled" required className={s.input} placeholder="@username" />
                                            )}
                                        />

                            </div>
                            <button className={`${s.btn} btn`} variant="primary" type="submit">
                                Send Request
                            </button>
                        </form>
                    </div>
                    <hr />
                    {this.state.showMessage && <Alert>copied to clipboard</Alert>}
                    <Modal.Header className={`${s.modaleShare}`}>
                        <Button onClick={this.onButtonClickHandler} className={s.btn} title='copy url invitaion'><FaCopy /></Button>
                        <Button className={s.btn} title='share on facebook'><FaFacebook /></Button>
                        <Button className={s.btn} title='share on whatsapp'><FaWhatsapp /></Button>
                        <Button href='mailto:donatelife@gmail.com' className={s.btn} title='send url email'><IoMail /></Button>
                    </Modal.Header>
                </div>
            </div>
        )
    }
}