import { CenterFocusStrong } from "@material-ui/icons";
import React,{ Component } from "react";
import {Line} from 'react-chartjs-2';

class InfoOne extends Component {
  render() {
    return (
      <div className="div-info1-home">
        <div className="div-info1-bar">
        <p style={{textAlign:"center"}}>% of national blood in stock</p>
          <Line
            data={{
              labels: ["A+","A-","B+", "B-","AB+", "AB-","O+","O-"],
              datasets: [
                {
                  label: ["National Blod Pourcentage"],
                  data: ["95", "20", "10", "12","15","8","70","18"],
                  backgroundColor: ["#024873", "#012840", "#5888A6", "#8FADBF"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ],
            }}
            width={200}
            height={120}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
          
        </div>
        <div className="div-info1-description">
            <hr/>
          <p style={{textAlign:"justify"}}>
            <h4 style={{textAlign:"center"}}>Pourcentage of National blood in stock</h4>
            this graphic represents the national average of each blood type
            in stock of all national centers,
            out of those statistics, the donor can constate shortage/stability/surplus of blood stack,
            otherwise, he can go to donate in case of shortage and not in incase of surplus to avoid the wastefulness of blood
          </p>
        </div>
      </div>
    );
  }
}

export default InfoOne;
