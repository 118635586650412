export const TabsProfileData =
    [
        {
            label: 'User info',
            link: '/profile/userinfo'
        },
        {
            label: 'Reports',
            link: '/profile/reports'
        },
        {
            label: 'Donnations',
            link: '/profile/donation'
        },
    ]