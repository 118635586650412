import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';
import NavProfile from './NavProfile'
import Logo from './Logo'
import NavNotification from './Notifications/NavNotification';
import NavLanguage from "./NavLanguage";
import NavUserName from './NavUserName';

import s from './NavBarUser.module.scss';

class NavBarUser extends Component
{
    render()
    {
        return (
            <div className={s.donorHeader}>
                <nav className={`${s.navBar} navbar navbar-expand-lg fixed-top`}>
                    <div className="container-fluid">

                        {/* this is a logo */}
                        <Logo />

                        <hr className={s.navBarHr} />

                        {/* form search bar */}
                            {this.props.NavSearchBar != null ? <this.props.NavSearchBar /> : null}

                        {/* Notofication icon with badge */}
                        <NavNotification NotificationData={this.props.NotifData}/>

                        {/* this button is hidden display on responsive */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i><FaBars className={`${s.navIconMenu}`} /></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent" >

                            {/* // navbar nav for icons and drop downs */}
                            <ul className={`${s.navbarNav} navbar-nav ms-auto mb-2 mb-lg-0`}>

                                {/* Language nav */}
                                <NavLanguage />

                                {/* Username nav */}
                                <NavUserName />

                                {/* Avatar Profile with badge*/}
                                <NavProfile />
                            </ul>
                        </div>
                    </div>
                </nav >
            </div>
        );
    };
};

export default NavBarUser;