import React, { Component } from "react";
import Tabs from "../../../GlobaleComponents/Tabs/Tabs";

import { TabsProfileData } from "./Data/TabsProfileData";
import { PagesRouteProfileData } from "./Data/PagesRouteProfileData";

class Profile extends Component
{
  render()
  {
    return (
      <>
        <Tabs TabsData={TabsProfileData} PagesRouteData={PagesRouteProfileData}/>
      </>
    );
  }
}

export default Profile;