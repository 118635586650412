import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import s from "./Details.module.scss";
import PersonalPic from "../vide.png";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Search from "../Search/Search";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Details extends Component {
  render() {
    return (
      <div>
        <Search />
        <Container className={s.detailsContainer}>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <img
                src={PersonalPic}
                alt="Personal Picture"
                className={s.imgProfile}
              />
            </Col>
            <Col lg={8} md={12} sm={12}>
              {" "}
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  First Name :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="text"
                  placeholder="First Name .."
                  className={s.inputsTop}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Last Name :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="text"
                  placeholder="Last Name .."
                  className={s.inputsTop}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  CIN :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="text"
                  placeholder="CIN .."
                  className={s.inputsTop}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Birthday :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="date"
                  placeholder="Birthday .."
                  className={s.inputsTop}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              {" "}
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Status :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="text"
                  placeholder="Status ..."
                  className={s.inputsBottom}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Phone :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="tel"
                  placeholder="Phone .."
                  className={s.inputsBottom}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Email :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="email"
                  placeholder="Email .."
                  className={s.inputsBottom}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" className={s.labels}>
                  Adress :
                </Form.Label>
                <Form.Control
                  sm="10"
                  size="sm"
                  type="text"
                  placeholder="Adress .."
                  className={s.inputsBottom}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Container className={s.containerBtns}>
          <Button size="sm" className={s.btnEdit}>
            Edit
          </Button>{" "}
          <Button size="sm" className={s.btnCancel}>
            Cancel
          </Button>
        </Container>
      </div>
    );
  }
}

export default Details;
