import React, { Component } from "react";
import FormLogin from "./childComponents/formLogin/formLogin";
import NavbarLogin from "./childComponents/formLogin/navbarLogin";
import Footer from "./childComponents/footer/footer";


class Login extends Component {
  render() {
    return (
      <div>
        <NavbarLogin />
        <FormLogin />
        <Footer />
      </div>
    );
  }
}

export default Login;
