import SideMenuBar from "../GlobaleComponents/SideMenuBarUser/SideMenuBar";
import NavBarUser from "../GlobaleComponents/NavBarUser/NavBarUser";
import Main from "./componenets/Main/main";
import Home from "./componenets/Home/Home";
import Comm from "./componenets/Comm/Comm";
import { NotificationData } from './componenets/Data/NotificationData';

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.css";
import "./style/DirectorGenerale.scss";
//import { withRouter } from "react-router";

import { FaHome, FaSitemap, FaCommentDots } from "react-icons/fa";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

function App() {
  const sideData = [
    {
      title: "Home",
      link: "/directorgenerale/home",
      icon: <FaHome />,
      cName: "menuItemsIconText",
    },
    {
      title: "Management",
      link: "/directorgenerale/management",
      icon: <FaSitemap />,
      cName: "menuItemsIconText",
    },
    {
      title: "Informations",
      link: "/directorgenerale/informations",
      icon: <FaCommentDots />,
      cName: "menuItemsIconText",
    },
  ];

  return (
    <div>
      <NavBarUser  NotifData={NotificationData}></NavBarUser>
      <SideMenuBar sideData={sideData} ></SideMenuBar>

      <main className="main-container">
        <Switch>
          <Route path="/directorgenerale" exact>
            <Redirect to="/directorgenerale/home" />
          </Route>
          <Route path="/directorgenerale/home">
            <Home></Home>
          </Route>
          <Route path="/directorgenerale/management">
            <Main />
          </Route>

          <Route path="/directorgenerale/informations">
            <Comm></Comm>
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default App;
