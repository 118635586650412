import Tabs from "../../GlobaleComponents/Tabs/Tabs";
import React, { Component } from "react";

import { TabsHomeData } from "./Data/TabsHomeData";
import { PagesRouteHomeData } from "./Data/PagesRouteHomeData";

class Home extends Component
{
    render()
    {
        return (
            <div>
                <Tabs TabsData={TabsHomeData} PagesRouteData={PagesRouteHomeData} />
            </div>
        );
    }
}

export default Home;
