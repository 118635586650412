import Tabs from "../../GlobaleComponents/Tabs/Tabs";
import React, { Component } from "react";

import { TabsManagementData } from "./Data/TabsManagementData";
import { PagesRouteManagementData } from "./Data/PagesRouteManagementData";

class Management extends Component
{
    render()
    {
        return (
            <>
                <Tabs TabsData={TabsManagementData} PagesRouteData={PagesRouteManagementData}/>
            </>
        );
    }
}

export default Management;
