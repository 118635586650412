export const CenterData =
    [
        {
            value: 'Tanger Blood injection',
            label: 'Tanger Blood injection'
        },
        {
            value: 'Tetouan Blood injection',
            label: 'Tetouan Blood injection'
        },
        {
            value: 'Fes Blood injectionmane',
            label: 'Fes Blood injectionmane'
        },
        {
            value: 'Rabat Blood injection',
            label: 'Rabat Blood injection'
        },
        {
            value: 'Meknes Blood injection',
            label: 'Meknes Blood injection'
        },
        {
            value: 'Laeyoune Blood injection',
            label: 'Laeyoune Blood injection'
        },
        {
            value: 'Guelmim Blood injection',
            label: 'Guelmim Blood injection'
        },
        {
            value: 'Casa Blanca Blood injection',
            label: 'Casa Blanca Blood injection'
        },
        {
            value: 'Marrakech Blood injection',
            label: 'Marrakech Blood injection'
        },
        {
            value: 'Tafilalet Blood injection',
            label: 'Tafilalet Blood injection'
        },
        {
            value: 'Nador Blood injection',
            label: 'Nador Blood injection'
        },
        {
            value: 'Sale Blood injection',
            label: 'Sale Blood injection'
        },
        {
            value: 'Tadla Blood injection',
            label: 'Tadla Blood injection'
        },
        {
            value: 'Errachidida',
            label: 'Errachidida'
        },
        {
            value: 'Souss Massa Blood injection',
            label: 'Souss Massa Blood injection'
        },
        {
            value: 'Taza-Al Hoceima Blood injection',
            label: 'Taza-Al Hoceima Blood injection'
        },
    ];