export const TabsHomeData =
    [
        {
            label: 'Statistical',
            link: '/directorcenter'
        },
        {
            label: 'Emergency Case',
            link: '/directorcenter/emergencycase'
        },
        {
            label: 'Generate Post',
            link: '/directorcenter/generatepost'
        },
    ]