import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IoCalendarSharp } from "react-icons/io5";
import InfoContainer from "./infoContainer";
import BloodInfo from "./bloodInfo";

import s from "./home.module.scss";
import SearchInput from "../../../GlobaleComponents/AutoCompletSelectSearch/SearchInput";
import { FaSearch } from "react-icons/fa";
import { HospitalCenterHome } from "./Data/HospitalCenterHome";

class Home extends Component
{
  render()
  {
    return (
      <div className={`container-fuild ${s.homePage}`}>

        <div className={s.selectButton}>

          <div className={s.inputSearch}>
            <SearchInput Options={HospitalCenterHome} placeholder='search center' id='homeCenterSearch' />
            <i className={s.iconSearch}>
              <FaSearch />
            </i>
          </div>

          <Link type="button" to='/appointment/stateregion' className={`${s.btnAppointment} btn`}>
              Fix Appointement
            <i className={s.icon}>
              <IoCalendarSharp className={s.icon} />
            </i>
          </Link>
        </div>

        <InfoContainer />
        <BloodInfo />
      </div>
    );
  }
}

export default Home;
