import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './Pages/Home/Home';
import Profile from './Pages/Profile/Profile';
import Appointment from './Pages/Appointment/Appointment';
import LockCenter from './Pages/LockCenter/LockCenter';
import Team from './Pages/Team/Team';

import NavSearchBar from './Components/NavBarUser/NavSearchBar';
import NavBarUser from '../GlobaleComponents/NavBarUser/NavBarUser';
import SideMenuBar from '../GlobaleComponents/SideMenuBarUser/SideMenuBar';

import { SideMenuData } from './Components/Data/SideMenuData';

import s from './css/App.module.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { NotificationData } from './Components/Data/NotificationData';
import { Share } from '../Settings/Share/Share';
import Setting from '../Settings/Setting/Setting';
import { PrivacyPolicy } from '../Settings/Info/PrivacyPolicy';
import { TermConditions } from '../Settings/Info/TermConditions';

class App extends Component
{
    render()
    {
        return (
            <Router>
                <NavBarUser NavSearchBar={NavSearchBar} NotifData={NotificationData} />
                <SideMenuBar sideData={SideMenuData} />

                <main className={`${s.mainContainer} py-4 main-container`}>
                    <Switch>
                        <Route path='/donor'>
                            <Home />
                        </Route>
                        <Route exact path='/profile/userinfo'>
                            <Profile />
                        </Route>
                        <Route exact path='/appointment/stateregion'>
                            <Appointment />
                        </Route>
                        <Route exact path='/team/register'>
                            <Team />
                        </Route>
                        <Route exact path='/lockcenter'>
                            <LockCenter />
                        </Route>
                        <Route exact path='/share'>
                            <Share />
                        </Route>
                        <Route exact path='/setting'>
                            <Setting />
                        </Route>
                        <Route exact path='/privacy_policy'>
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path='/terms'>
                            <TermConditions />
                        </Route>
                    </Switch>
                </main>
            </Router>
        );
    };
};
export default App;