export const TabsTeamData =
    [
        {
            label: 'Posts',
            link: '/team/main/posts'
        },
        {
            label: 'Members',
            link: '/team/main/members'
        },
        {
            label: 'Reports',
            link: '/team/main/reports'
        },
    ]