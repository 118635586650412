import Select from "../../../GlobaleComponents/Select/Select";
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { BloodTypeParametersData } from "../ParameterData/BloodTypeParametersData";

import style from '../Add/Add.module.scss';

export default function Remove()
{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <div className={`${style.add} container`}>
            <h5 className={style.title}>Reducing blood stats</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={`${style.form}`}>

                <Form.Group controlId="formBasicEmail" className={style.inputGroup}>
                    <Select items={BloodTypeParametersData} required/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a blood type.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="red blood cells quantity" max='-1' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please mines red blood cells quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="cryoprecipitate quantity" max='-1' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please mines a cryoprecipitate quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="platelets quantity" max='-1' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please mines a platelets quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="number" placeholder="frozen plasma quantity" max='-1' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please mines a frozen plasma quantity.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className={style.btnSubmit}>
                    Remove Blood Static
                </Button>
            </Form>
        </div>
    );
}