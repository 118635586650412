import { name, username } from "react-lorem-ipsum";

export const rows = [
    { id: 1, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 2, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 3, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 4, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 5, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 6, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 7, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 8, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 9, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 10, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 11, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 12, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 13, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 14, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 15, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 16, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 17, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 18, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 19, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 20, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 21, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 22, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 23, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 24, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 25, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 26, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 27, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 28, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 29, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 30, cin: 'LF123456', fullName: username(), disease: name() },
    { id: 31, cin: 'LF123456', fullName: username(), disease: name() },
];