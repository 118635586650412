import React, { Component } from 'react';
import { FaCog, FaInfoCircle, FaPowerOff, FaShareSquare } from 'react-icons/fa';
import { username } from 'react-lorem-ipsum';
import { Link } from 'react-router-dom';

import s from './NavBarUser.module.scss';

class NavUserName extends Component
{
    render()
    {
        return (
            <li className={`${s.navItem} nav-item dropdown`}>
                <Link
                    className={`${s.navLink} nav-link dropdown-toggle`}
                    to="/dropdown" id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {username()}
                </Link>
                <ul
                    className={`${s.dropdownMenu} dropdown-menu`}
                    aria-labelledby="navbarDropdown">
                    <li>
                        <Link className={`${s.dropdownItem} dropdown-item`} to="/privacy_policy">Info <FaInfoCircle style={{ float: 'right', marginTop: '5px' }} /></Link>
                    </li>
                    <li>
                        <Link className={`${s.dropdownItem} dropdown-item`} to="/share">Share <FaShareSquare style={{ float: 'right', marginTop: '5px' }} /></Link>
                    </li>
                    <li>
                        <Link className={`${s.dropdownItem} dropdown-item`} to="/setting">Setting <FaCog style={{ float: 'right', marginTop: '5px' }} /></Link>
                    </li>
                    <li>
                        <Link className={`${s.dropdownItem} dropdown-item`} to="/desconnect">Desconnect <FaPowerOff style={{float: 'right', marginTop:'5px'}}/></Link>
                    </li>
                </ul>
            </li>
        );
    };
};

export default NavUserName;