import React from "react";
import { Link } from "react-router-dom";
import pageNfound from "../../pageNfound.svg";
import s from "./Page404.module.scss";

export default function Page404()
{
  return (
    <div className={`${s.pageNfound} container position-absolute top-50 start-50 translate-middle`}>
      <div className={s.imageContainer}>
        <img src={pageNfound} alt='page not found'/>
      </div>
      <span className={s.title}>
        Oops... <p className={s.txt}>Page not found</p>
      </span>
      <Link to="/" className={s.redirect}>
        Go to home page
      </Link>
    </div>
  );
}
