import React, { Component } from 'react';
import Post from './GeneratePost';

import s from './GeneratePosts.module.scss';

class GeneratePosts extends Component
{
    render()
    {
        return (
            <div className={`${s.posts} container`}>
                <div className={s.form}>
                    <form className={s.form}>
                        <textarea className={s.textArea} id="news" name="news" rows="4" placeholder="what's news" />
                        <input type='submit' className={`${s.btnPrimary} btn`} value='Post' />
                    </form>
                </div>

                <Post />

            </div>
        )
    }
}

export default GeneratePosts;