export const HospitalCentersParametersData =
    [
        {
            label: 'Tetouan Saniat Rmel Center',
            value: 'A1254S87A'
        },
        {
            label: 'Tanger Center',
            value: 'T54979GR8'
        },
        {
            label: 'Rabat Center',
            value: 'Q54979GR8'
        },
        {
            label: 'Khribga Blood Center',
            value: 'VW568979Q'
        }
    ];