import Statistical from '../Statistical/Statistical';
import Emegency from '../Emergency/Emergency';
import GeneratePosts from '../GeneratePost/GeneratePosts';

export const PagesRouteHomeData =
    [
        {
            page: <Statistical />,
            link: '/directorcenter'
        },
        {
            page: <Emegency />,
            link: '/directorcenter/emergencycase'
        },
        {
            page: <GeneratePosts />,
            link: '/directorcenter/generatepost'
        },
    ]