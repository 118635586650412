import SearchInput from '../../../../../GlobaleComponents/AutoCompletSelectSearch/SearchInput';
import React, { Component } from 'react';
import { FaSearch } from 'react-icons/fa';
import Member from './Member';
import { SearchMemberTeamData } from '../../TeamPages/Members/Data/SearchMemberTeamData';

import s from './Members.module.scss';

import Modale from './Modale/Modale';

class Members extends Component
{
    render()
    {
        const style = {
            backgroundColor: '#32aeb8',
            padding: '10px',
            color: '#fff',
            fontWeight: 'bold',
            float: 'right',
            marginBottom: '16px',
            border: '1px solid #bac8d9',
        };

        return (
            <div className={s.members} >
                <div className={s.inputSearch}>
                    <SearchInput Options={SearchMemberTeamData} placeholder='search member' id='searchMember' />
                    <i className={s.iconSearch}>
                        <FaSearch />
                    </i>
                </div>
                <Modale buttonText={"Add Member"} buttonStyle={style} title={'Add Team Member'} />
                <div>
                    <Member />
                </div>
            </div>
        )
    }
}

export default Members;