export const BloodTypeParametersData =
    [
        {
            value: 'A',
            label: 'A'
        },
        {
            value: 'B',
            label: 'B'
        },
        {
            value: 'O',
            label: 'O'
        },
        {
            value: 'AB',
            label: 'AB'
        },
        {
            value: 'A+',
            label: 'A+'
        },
        {
            value: 'A-',
            label: 'A-'
        },
        {
            value: 'B+',
            label: 'B+'
        },
        {
            value: 'B-',
            label: 'B-'
        },
        {
            value: 'AB+',
            label: 'AB+'
        },
        {
            value: 'AB-',
            label: 'AB-'
        },
    ];