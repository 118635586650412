import react from "react";
import { Image } from "react-bootstrap";
import { Avatar } from "react-lorem-ipsum";

function ImageDiv(props) {
  return (
    <div className="ImageDiv">
      <div className="imgD">
        {/* <Image src={props.image} alt="default image" fluid></Image> */}
        <Avatar gender="all" className="avatar" alt="Avatar" />
      </div>
    </div>
  );
}
export default ImageDiv;
