import React, { Component } from "react";
import { Form, Button, Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import s from "./Form.module.scss";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

class Formone extends Component {
  render() {
    return (
      <Container>
        <Form className={s.mainform}>
          <Form.Group>
            <Form.Control
              size="sm"
              placeholder="CIN (*) ..."
              className={s.FormControl}
            />
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
          <Form.Group>
            <Form.Control
              size="sm"
              placeholder="FullName (*) ..."
              className={s.FormControl}
            />
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
          <Form.Group>
            <Form.Control
              size="sm"
              placeholder="Birth Place (*) ..."
              className={s.FormControl}
            />
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="inlineFormCustomSelectPref"
              custom
              className={s.FormControl}
            >
              <option value="0">Country</option>
              <option value="1">Morocco</option>
              <option value="2">France</option>
              <option value="3">England</option>
            </Form.Control>
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
          <Row>
            <Col md={6} className={s.FormControlColumn}>
              <Form.Control
                as="select"
                className="my-0 mr-sm-0"
                id="inlineFormCustomSelectPref"
                custom
                className={s.FormControl}
              >
                <option value="0">State</option>
                <option value="1">Rabat-sale</option>
                <option value="2">Tetouan-Tanger-Houcima</option>
                <option value="3">Grand-Casablanca</option>
              </Form.Control>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
            <Col md={6} className={s.FormControlColumn}>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                custom
                className={s.FormControl}
              >
                <option value="0">City</option>
                <option value="1">Fnideq</option>
                <option value="2">Tetouan</option>
                <option value="3">Tanger</option>
              </Form.Control>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
          </Row>
          <Form.Group>
            <Form.Control
              size="sm"
              placeholder="Adresse Line 1 (*) ..."
              className={s.FormControl}
            />
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
          <Row>
            <Col xs="6" className={s.FormControlColumn}>
              <Form.Control
                size="sm"
                className={s.FormControl}
                placeholder="Adresse Ligne 2 ..."
              ></Form.Control>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
            <Col xs="6" className={s.FormControlColumn}>
              <Form.Control
                size="sm"
                className={s.FormControl}
                placeholder="Code Postal ..."
              ></Form.Control>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
          </Row>
          <Row>
            <Col xs="2" className={s.FormControlColumn}>
              <Form.Control
                as="select"
                className="my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                custom
                className={s.FormControl}
              >
                <option value="0">+212</option>
                <option value="1">+340</option>
                <option value="2">+450</option>
                <option value="3">+550</option>
              </Form.Control>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
            <Col xs="10" className={s.FormControlColumn}>
              <Form.Control
                size="sm"
                className={s.FormControl}
                placeholder="###-###-###"
              ></Form.Control>
              <Button className={s.send}>Send</Button>
              <CheckCircleRoundedIcon className={s.validicon} />
            </Col>
          </Row>
          <Form.Group>
            <Form.Control
              size="sm"
              placeholder="6 Digits Recived ..."
              className={s.FormControl}
              disabled
            />
            <CheckCircleRoundedIcon className={s.validicon} />
          </Form.Group>
        </Form>
        <Button className={s.add}>Add</Button>
      </Container>
    );
  }
}

export default Formone;
