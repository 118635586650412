import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Center } from './Center/Center';
import { City } from './City/City';
import { StateRegion } from './StateRegion/StateRegion';
import { DateTime } from './DateTime/DateTime';
import { Succes } from './Succes/Succes';
import NoMatch from '../../../GlobaleComponents/Page404/Page404';

import s from './Appointment.module.scss'

class Appointment extends Component
{
    render()
    {
        return (
            <Router>
                <div className={`${s.appointment} container`}>
                    <Switch>
                        <Route exact path='/appointment/stateregion'>
                            <StateRegion />
                        </Route>
                        <Route exact path='/appointment/stateregion/city'>
                            <City />
                        </Route>
                        <Route exact path='/appointment/stateregion/city/center'>
                            <Center />
                        </Route>
                        <Route exact path='/appointment/stateregion/city/center/datetime'>
                            <DateTime />
                        </Route>
                        <Route exact path='/appointment/stateregion/city/center/datetime/succes'>
                            <Succes />
                        </Route>
                        <Route path='*'>
                            <NoMatch />
                        </Route>
                    </Switch>
                </div>
            </Router >
        )
    }
}

export default Appointment;