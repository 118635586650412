import SearchInput from "../../../GlobaleComponents/AutoCompletSelectSearch/SearchInput";
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';

import { StateManagementData } from "../Data/StateManagementData";
import { CityManagementData } from "../Data/CityManagementData";
import { HospitalCentersManagementData } from "../Data/HospitalCentersManagementData";
import style from './Add.module.scss';

export default function Add()
{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div className={`${style.add} container`}>
            <h5 className={style.title}>Add new employee</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={style.form}>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" placeholder="CIN (*)" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add CIN (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" placeholder="PPR (*)" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add PPR (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" placeholder="first name (*)" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add first name (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" placeholder="nick name" className={style.input} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="text" placeholder="phone number (*)" pattern='^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add phone number (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="email" placeholder="email (*)" pattern='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add email (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type="date" placeholder="birthday (*)" className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add birthday (*).
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className={style.inputGroup}>
                    <SearchInput Options={StateManagementData} id='stateList' required placeholder='choose state or region' />
                    <Form.Control.Feedback type="invalid">
                        Please choose a state or region.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className={style.inputGroup}>
                    <SearchInput Options={CityManagementData} required placeholder='choose city' id='cityList'/>
                    <Form.Control.Feedback type="invalid">
                        Please choose a city.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className={style.inputGroup}>
                    <SearchInput Options={HospitalCentersManagementData} id='hospitaCenterList' required placeholder='choose center/hospital' />
                    <Form.Control.Feedback type="invalid">
                        Please choose a hospital/center.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className={style.btnSubmit}>
                    Add Employee
                </Button>
            </Form>
        </div>
    );
}