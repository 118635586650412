import * as icon from 'react-icons/fa';

export const SideData = [
    {
        title: 'Home',
        link: '/directorcenter',
        icon: <icon.FaHome />,
    },
    {
        title: 'Employees',
        link: '/management/employees',
        icon: <icon.FaSitemap />,
    },
    {
        title: 'Parameters',
        link: '/parameters/add',
        icon: <icon.FaDatabase />,
    },
    {
        title: 'Center',
        link: '/center/storage',
        icon: <icon.FaHospital />,
    },
];