import React, { Component } from "react";
import FormRegister2 from "./childComponents/formRegister/formRegister2";
import NavbarHead from "./childComponents/formRegister/navbarRegister2";
import Footer from "./childComponents/footer/footer";

class Register2 extends Component {
  render() {
    return (
      <div>
        <NavbarHead />
        <FormRegister2 />
        <Footer/>
      </div>
    );
  }
}
export default Register2;
