import React, { Component } from "react";
import { Doughnut } from 'react-chartjs-2';

import s from './Statistical.module.scss';

class ChartThree extends Component
{
    render()
    {
        return (
            <div className={s.chartThree}>
                <p>% of Blood type in Tetouan/Tanger</p>
                <Doughnut
                    data={{
                        labels: ["A", "B", "AB", "O", "AB-"],
                        datasets: [
                            {
                                label: ["National Blood Pourcentage"],
                                data: ["55", "33", "20", "4", "40"],
                                backgroundColor: ["#D93BAF", "#F294D9", "#D91ACC", "#4F1BBF", "#48BED9"],
                                borderColor: "#bac8d9",
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

export default ChartThree;
