import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Search.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import Form from "react-bootstrap/Form";

class SearchInput extends Component {
  render() {
    return (
      <div>
        <Form.Control
          size="sm"
          type="text"
          placeholder="Enter donor's CIN .."
          className={`${styles.searchInput} form-control form-control-sm`}
        />
        <button className={`${styles.searchIcon}`}>
          <SearchIcon />
        </button>
      </div>
    );
  }
}

export default SearchInput;
