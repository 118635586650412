import SearchInput from "../../../GlobaleComponents/AutoCompletSelectSearch/SearchInput";
import React, { Component } from "react";
import ChartOne from "./ChartOne";
import ChartThree from "./ChartThree";
import ChartTwo from "./ChartTwo";
import { HospitalCentersHomeData } from "../Data/HospitalCentersHomeData";

import s from './Statistical.module.scss';
import LoremIpsum from "react-lorem-ipsum";
import { FaFilter, FaSearch } from "react-icons/fa";

class Statistical extends Component
{
    render()
    {
        return (
            <div className={`${s.statistical} container`}>
                <div className={s.line}>
                    <hr className={s.lineHr} />
                    <i className={s.btnFilter} type='button' data-bs-toggle="dropdown" aria-expanded="false">Filter <FaFilter /></i>
                    <ul className={`${s.items} dropdown-menu`}>
                        <li className={s.item}>By Name</li>
                        <li className={s.item}>By Date</li>
                    </ul>
                </div>

                <div className={s.inputSearch}>
                    <SearchInput Options={HospitalCentersHomeData} placeholder='check centers...' id='centersDirectorCenter' />
                    <i className={s.iconSearch}>
                        <FaSearch />
                    </i>
                </div>
                <div className={s.descriptionChart}>
                    <div className={s.discription}>
                        <span>Centre national de transfusion Sanguine</span><br />
                        <span>Address: Rabat</span><br />
                        <span > work hours:</span ><br />
                        <ul>
                            <li>Friday 8 AM-5:30 PM</li>
                            <li>Saturday 8:30 AM - 2 PM</li>
                            <li>Sunday is closed</li>
                            <li>Monday 8am-5:30 PM</li>
                            <li>Tuesday 8am-5:30 PM</li>
                            <li>Wednesday 8 AM-5:30 PM</li>
                            <li>Thursday 8am-5:30 PM</li>
                        </ul>
                        <span>Closed ⋅ Will open at 8:30 AM Saturday</span><br />
                        <span > Phone: 05377 - 74993</span >
                    </div>

                    <ChartThree />

                </div>

                <div className={s.charts}>
                    <ChartOne />
                    <ChartTwo />
                    <ChartThree />
                </div>
            </div>
        )
    }
}

export default Statistical;