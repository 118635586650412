import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';


import { Grid, Button } from '@material-ui/core';

import { rows } from './Data/UploadRowsData';
import SearchInput from '../../GlobaleComponents/AutoCompletSelectSearch/SearchInput';
import { FaSearch, FaUpload } from 'react-icons/fa';
import { UploadSearchData } from './Data/UploadSearchData';

import { useStyles } from "./TableUploadStyle";

export default function TableUpload()
{
  const classes = useStyles();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'cin',
      headerName: 'CIN',
      width: 150,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 250,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'disease',
      headerName: 'Disease',
      width: 250,
      editable: false,
      headerAlign: 'center',
      textAlign: 'center'
    },
    {
      field: 'fillModele',
      headerName: 'Fill Modele',
      width: 250,
      renderCell: () => (
        <Button
          variant="contained"
          color="inherit"
          size="small"
          className={classes.btnPrimary}
        >
          Modele
        </Button>
      ),
    },
    {
      field: 'uploadReport',
      headerName: 'Upload Report',
      width: 250,
      renderCell: () => (
        <Button
          variant="contained"
          color="inherit"
          size="small"
          className={classes.btnPrimary}
        >
          Upload
        </Button>
      ),
    },
  ];

  return (
    <div className={`${classes.tableProfile} container`}>

      <div className={classes.inputSearch}>
        <SearchInput Options={UploadSearchData} placeholder='search donor (cin)' id='donorCin' />
        <i className={classes.iconSearch}>
          <FaSearch />
        </i>
      </div>

      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.root}
        style={{ height: '70vh', backgroundColor: 'white' }}>
        <DataGrid
          density='comfortable'
          rows={rows}
          columns={columns}
        />
      </Grid>
    </div>
  );
}
