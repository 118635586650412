import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    '@media (max-width: 991px)': {
    },

    modale:{
        backgroundColor: '#e4e4e4',
    },
    modaleHeader: {
        border: 'none',
    },

    grpGrCodeUser: {
        display: 'block',
        border: '1px solid #bac8d9',
        padding: '16px',
        borderRadius: '5px',
    },
    h5:{
        textAlign: 'center',
        fontSize: 'small'
    },
    btnPrimary: {
        backgroundColor: '#32aeb8',
        color: '#fff',
        fontWeight: 'bold',
        border: '1px solid #bac8d9',
        boxShadow: 'none',
        padding: '10px',
        textTransform: 'capitalize',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#fff',
            color: '#32aeb8',
            border: '1px solid #bac8d9',
        },
    },

    btnSecondary: {
        border: '1px solid #bac8d9',
        boxShadow: 'none',
        padding: '10px',
        backgroundColor: '#f4f4f4',
        color: '#8f8f8f',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#32aeb8',
            boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.01), 0 5px 10px 0 rgba(0, 0, 0, 0.1) !global',
        },
    },
});