export const TabsManagementData =
    [
        {
            label: 'Employees',
            link: '/management/employees'
        },
        {
            label: 'Add',
            link: '/management/add'
        },
    ]