import react, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Search from "../Search/search";
import Post from "./Post/post";
import Bloodst from "./BloodStatistic/Bloodst";
import style from "./Home.module.scss";

class Home extends Component {
  state = {
    posts: [
      {
        id: 1,
        topic: <p>Learn the full spectrum of COVID-19 symptoms. The most common symptoms of COVID-19 are fever, dry cough and fatigue. Other less common symptoms that may affect some patients include loss of taste or smell, aches and pains, headache, sore throat, nasal congestion, red eyes, diarrhea, and rash.
          Stay home and isolate yourself even if you have mild symptoms such as cough, headache and mild fever, until you recover. Call your healthcare provider or hotline for advice. Ask someone else to bring you the purchases. And if you have to leave the house or summon someone to stay with you, wear a mask to avoid spreading the infection to others.
          If you have a fever, cough and difficulty breathing, seek medical attention immediately. Phone first if you can, and follow directions from your local health authority.</p>,
      },
      {
        id: 1,
        topic: "this is a random text .... this is a random text",
      },
      {
        id: 1,
        topic: "this is a random text .... this is a random text",
      },
    ],
  };
  render() {
    return (
      <div className={style.home}>
        <Container className={style.homePage}>
          <Row className={style.HomeRow}>
            <Col>
              <Search place="search for center by name or by reference" />
            </Col>
          </Row>

          <Row className={style.HomeRow}>
            <Col lg={8}>
              <Bloodst></Bloodst>
            </Col>
            <Col lg={4}>
              <div className={style.pInfo}>
                <p style={{textAlign:"justify"}}>
                  <h4 style={{textAlign:"center"}}>percentage of fullness in the centers</h4>
                  this graphic represents the state of fullness in all the centers spread on the whole territory of Morocco
                </p>
              </div>
            </Col>
          </Row>
          <ul className="ulCards ulHome">
            {this.state.posts.map((item) => (
              <li>
                <Post topic={item.topic}></Post>
              </li>
            ))}
          </ul>
        </Container>
      </div>
    );
  }
}
export default Home;
