import Message from '../Message/Message';
import Storage from '../Storage/Storage';

export const PagesRouteCenterData =
    [
        {
            page: <Storage />,
            link: '/center/storage'
        },
        {
            page: <Message />,
            link: '/center/editmessages'
        },
    ]