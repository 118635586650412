import react, { Component } from "react";

import * as icon from "react-icons/fa";
import { Card } from "react-bootstrap";

function InfoCard(props) {
  return (
    <Card className="Mycard">
      <div className="btn-option-member">
        <i type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <icon.FaEllipsisV />
        </i>
        <ul className="items dropdown-menu">
          <li className="item">Delete</li>
          <li className="item">Edit</li>
        </ul>
      </div>

      <ul>
        <li>
          <p>{props.name}</p>
          <hr className="hr"></hr>
        </li>
        <li>
          <p>{props.adress}</p>
          <hr></hr>
        </li>
        <li>
          <p>{props.param1}</p>
          <hr></hr>
        </li>
        <li>
          <p>{props.param2}</p>
          <hr></hr>
        </li>
        <li>
          <p>{props.param3}</p>
        </li>
      </ul>
    </Card>
  );
}

export default InfoCard;
