export const CityData =
    [
        {
            value: 'Tetouan',
            label: 'Tetouan'
        },
        {
            value: 'Tanger',
            label: 'Tanger'
        },
        {
            value: 'Fesmane',
            label: 'Fesmane'
        },
        {
            value: 'Rabat',
            label: 'Rabat'
        },
        {
            value: 'Casablanca',
            label: 'Casablanca'
        },
        {
            value: 'Laeyoune',
            label: 'Laeyoune'
        },
        {
            value: 'Guelmim',
            label: 'Guelmim'
        },
        {
            value: 'Lagouira',
            label: 'Lagouira'
        },
        {
            value: 'Marrakech',
            label: 'Marrakech'
        },
        {
            value: 'Meknes',
            label: 'Meknes'
        },
        {
            value: 'Bojdour',
            label: 'Bojdour'
        },
        {
            value: 'Tadla',
            label: 'Tadla'
        },
        {
            value: 'Nador',
            label: 'Nador'
        },
        {
            value: 'Oujda',
            label: 'Oujda'
        },
        {
            value: 'Taza',
            label: 'Taza'
        },
    ];