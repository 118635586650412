import react, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHospital } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import Search from "../../Search/search";
import AddNew from "../../AddNewBtn/AddNew";
import InfoCard from "../../Card/card";

class Centers extends Component {
  state = {
    centers: [
      {
        name: "Center of: Tetouan ",
        adress: "Adress : Saniya dermal",
        param1: "Centre director : Dr. Mouad benchkroun",
        param2: "Numbre of Staff : 9 person",
        param3: "Number of Emergencies per monthe : 18 Emergencies",
      },
      {
        name: "Center of: Tanger ",
        adress: "Adress : Rue fès alwilaya",
        param1: "Centre director : Dr. karima ELKhayti",
        param2: "Numbre of Staff : 15 person",
        param3: "Number of Emergencies per monthe : 25 Emergencies",
      },
      {
        name: "Center of: CasaBlanca ",
        adress: "Adress : Ain Chouk",
        param1: "Centre director : Dr. Mohamed Aziz", 
        param2: "Numbre of Staff : 35 person",
        param3: "Number of Emergencies per monthe : 50 Emergencies",
      },
    ],
  };
  render() {
    return (
      <div>
        <div className="search">
          <div>
            <div class="mainSearch">
              <Row>
                <Col
                  xs={{ order: 2 }}
                  sm={{ order: 2 }}
                  md={{ span: 8, order: 1 }}
                  className="colsearch"
                >
                  <Search place="search a center"></Search>
                  {/*md={{ span: 3, offset: 1 }}*/}
                </Col>

                <Col
                  sm={{ span: 5, offset: 7, order: 1 }}
                  xs={{ span: 5, offset: 7, order: 1 }}
                  md={{ span: 3, offset: 1, order: 2 }}
                  className="colButton"
                >
                  <AddNew
                    path="/directorgenerale/management/centers/add-center"
                    icon={faHospital}
                    text="Add center"
                  ></AddNew>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ul className="ulCards">
          {this.state.centers.map((item, index) => (
            <li>
              <InfoCard
                name={item.name}
                adress={item.adress}
                param1={item.param1}
                param2={item.param2}
                param3={item.param3}
              ></InfoCard>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Centers;
