import Select from "../../../GlobaleComponents/Select/Select";
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';

import { BloodTypeEmergencyData } from "../Data/BloodTypeEmergencyData";

import style from './Emergency.module.scss';

export default function Emergency()
{
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) =>
    {
        const form = event.currentTarget;
        if (form.checkValidity() === false)
        {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div className={`${style.emergency} container`}>
            <h5 className={style.title}>Emergency customization</h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className={`${style.form}`}>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control as="textarea" rows={3} placeholder="description of emergency message" maxLength='12' className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a description of emergency message.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Select items={BloodTypeEmergencyData} required />
                    <Form.Control.Feedback type="invalid">
                        Please add a description of emergency message.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className={style.inputGroup}>
                    <Form.Control type='number' placeholder="duration of the emergency message (day)" min={1} className={style.input} required />
                    <Form.Control.Feedback type="invalid">
                        Please add duration of the emergency message.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className={style.btnSubmit}>
                    Send
                </Button>
            </Form>
        </div>
    );
}