import { ReportDonorData } from "../Data/ReportDonorData";

import React, { Component } from 'react';
import { FaDownload } from 'react-icons/fa';

import s from './DonorReports.module.scss';

class Report extends Component
{
    render()
    {
        return (
            <>
                {
                    ReportDonorData.map((value, key) =>
                        <div className={`${s.card} card`} key={key}>
                            <div className="card-body">
                                <i className={s.btnOptionReport} type='button'><FaDownload /></i>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">Title:: {value.title}</span>
                                    <hr className={s.hr} />
                                </div>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">name:: {value.name}</span>
                                    <hr className={s.hr} />
                                </div>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">Center:: {value.center}</span>
                                    <hr className={s.hr} />
                                </div>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">City:: {value.city}</span>
                                    <hr className={s.hr} />
                                </div>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">Period:: {value.period}</span>
                                    <hr className={s.hr} />
                                </div>
                                <div className={s.cardBodyItems}>
                                    <span className="card-text">Type:: {value.type}</span>
                                    <hr className={s.hr} />
                                </div>
                                <span><small className={s.date}>Date:: {value.day} - {value.month} - {value.year} ({value.hour})</small></span>
                            </div>
                        </div>
                    )
                }
            </>
        )
    }
}

export default Report;