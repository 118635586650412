import react, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import image from "../../../../levi.jpg";
import ImageDiv from "../../../ImageDiv/ImageDiv";
import * as icon from "react-icons/fa";

function DeriCard(props) {
  return (
    <div className="deriCardContainer">
      <Card className="Mycard">
        <div className="btn-option-member">
          <i type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <icon.FaEllipsisV />
          </i>
          <ul className="items dropdown-menu">
            <li className="item">Delete</li>
            <li className="item">Edit</li>
          </ul>
        </div>

        <ul>
          <li>
            <Row>
              <Col xs={12} lg={{ span: 3, offset: 0 }}>
                <ImageDiv image={image} name={props.name}></ImageDiv>
              </Col>

              <Col xs={12} lg={9} className="directorInfo">
                <ul>
                  <li>
                    <p>{props.name}</p>
                    <hr className="hr"></hr>
                  </li>
                  <li>
                    <p>{props.adress}</p>
                    <hr></hr>
                  </li>
                  <li>
                    <p>{props.param1}</p>
                    <hr></hr>
                  </li>
                  <li>
                    <p>{props.param2}</p>
                    <hr></hr>
                  </li>
                  <li>
                    <p>{props.param3}</p>
                  </li>
                </ul>
              </Col>
            </Row>
          </li>
        </ul>
      </Card>
    </div>
  );
}

export default DeriCard;
