import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import s from './NavBarUser.module.scss';

class NavLanguage extends Component
{
    render()
    {
        return (
            <li className={`${s.navItem} dropdown`}>
                <Link className={`${s.navLink} nav-link dropdown-toggle`} to="/dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Ar
                </Link>
                <ul className={`${s.dropdownMenu} dropdown-menu`} aria-labelledby="navbarDropdown">
                    <li><Link className={`${s.dropdownItem} dropdown-item`} to="/arabic">Arabic</Link></li>
                    <li><Link className={`${s.dropdownItem} dropdown-item`} to="/english">English</Link></li>
                    <li><Link className={`${s.dropdownItem} dropdown-item`} to="/fransh">Fransh</Link></li>
                </ul>
            </li>
        );
    };
};

export default NavLanguage;