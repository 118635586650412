import React from 'react';
import { Link } from 'react-router-dom';
import Select from '../../../../GlobaleComponents/Select/Select';
import { CenterData } from './Data/CenterData';
import s from '../Appointment.module.scss';

export function Center()
{
    return (
        <div className={`${s.card} position-absolute top-50 start-50 translate-middle card`}>
            <h5 className={`${s.cardHeader} card-header`}>To make an appointment You must follow these steps.</h5>
            <hr />
            <div className={`${s.cardBody} card-body`}>
                <h5 className={`${s.cardTitle} card-title`}>Second Step:: Select near Center</h5>

                <Select items={CenterData} selectBackColor={{ backgroundColor: "#eaf6f6" }} />

                <Link to="/appointment/stateregion/city/center/datetime" className={`${s.btn} btn`}>Next Step</Link>
                <Link to="/appointment/stateregion/city" className={`${s.btn} ${s.previous} btn`}>Previous</Link>
            </div>
        </div>
    )
}