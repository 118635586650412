import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
class InfoTow extends Component {
  render() {
    return (
      <div className="div-info2-home">
        <div className="div-info2-description">
          <hr />
          <p style={{textAlign:"justify"}}> 
          <h4 style={{textAlign:"center"}}>Pourcentage of blood donations by type</h4>
          this graphic represents the percentage of donation of each blood type all donations,
            as we see, people with blood type O- are the most donating ones, while persons with B-
            are the extremely opposite
          </p>
        </div>
        <div className="div-info2-doughnut">
          <p>% of Donor by blood type</p>
          <Doughnut
            data={{
              labels: ["A+","A-","B+", "B-","AB+", "AB-","O+","O-"],
              datasets: [
                {
                  label: ["% Donations by type"],
                  data: ["35", "6", "8", "2","4","1","37","7"],
                  backgroundColor: ["#D9BBB8", "#3E4259", "#231D26", "#BFA8BE"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default InfoTow;
