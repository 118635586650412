import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
class InfoFour extends Component {
  render() {
    return (
      <div className="div-info4-home">
        <div className="div-info4-description">
          <hr />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente,
            eveniet ex dolorum quibusdam in adipisci deserunt est pariatur
            minima incidunt quaerat inventore nostrum blanditiis similique
            repudiandae facilis vitae, omnis tempora cupiditate obcaecati!
            Dignissimos enim, eligendi excepturi reiciendis repudiandae maxime
            assumenda cumque sunt necessitatibus blanditiis rerum quaerat
            laborum ab ipsa earum. Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Deserunt nostrum sunt iusto molestiae quo labore
            autem iste, odio vel rerum quod vero fuga, cupiditate, ex quaerat.
            Cumque corporis iste amet. Lorem ipsum, dolor sit amet consectetur
            adipisicing elit. Deserunt nostrum sunt iusto molestiae quo
          </p>
        </div>
        <div className="div-info4-doughnut">
          <p>% of donors in 100000 (p)</p>
          <Doughnut
            data={{
              labels: ["A", "B", "AB", "O"],
              datasets: [
                {
                  label: ["National Blod Pourcentage"],
                  data: ["43", "33", "20", "4"],
                  backgroundColor: ["#90CBFB", "#110066", "#FFD432", "#003F63"],
                  borderColor: "#bac8d9",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default InfoFour;
