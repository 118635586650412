import LoremIpsum from "react-lorem-ipsum";

export const GeneratePostData =
    [
        {
            description: <div>
                Make it a habit to wear a mask when you are with other people. Proper use, preservation, cleaning and disposal of masks is essential to make them as effective as possible.
                Here is the basic information on how to wear a mask:

                Clean your hands before putting on the mask, and before and after taking it off.
                Make sure it covers your nose, mouth and chin.
                When you remove the mask, keep it in a clean plastic bag, and make sure to wash it daily if it is a cloth mask, or dispose of it in the waste bin if it is a medical mask.
                Do not use masks with valves.
                For more details on what type of mask to wear and when to wear it, please see the Q&A section and watch the videos on our website. There are also questions and answers that focus on masks and children.
                To learn more scientific information about the way a person becomes infected with COVID-19 and the way our bodies react, please watch or read this interview.
                For specific advice to decision makers, please see FAO's Technical Guidelines.
            </div>,
            date: '12/12/2021',
        },
        {
            description: <div>
                Avoid all three memes: enclosed, crowded, or close contact.
                Outbreaks are reported in restaurants, choir rehearsals, fitness classes, nightclubs, offices, places of worship where people gather, and often in crowded enclosed spaces where people are loudly talking, yelling, breathing heavily or singing.
                The risk of contracting COVID-19 increases in crowded, poorly ventilated spaces where infected people spend long periods of time together in close proximity to each other. It is these environments in which the virus appears to spread more efficiently via respiratory droplets or aerosols, so precautions are more important.
            </div>,
            date: '12/12/2020',
        },
        {
            description: <div>
                Don't forget the basics of good hygiene

                Regularly clean your hands thoroughly with an alcohol-based hand sanitizer or wash them with soap and water. This removes germs, including viruses, that may be on your hands.
                Avoid touching your eyes, nose and mouth. Hands touch many surfaces and can pick up viruses. If hands become contaminated, the virus can be transmitted to the eyes, nose or mouth. The virus can enter your body through these ports and cause you to become ill.
                Cover your mouth and nose with your elbow or a tissue when coughing or sneezing. Then dispose of the tissue immediately in a closed trash can. By following good respiratory hygiene practices, you protect the people around you from viruses such as the viruses that cause colds, influenza and COVID-19.
                Clean and disinfect surfaces frequently, especially those that are touched regularly, such as door handles, faucets, and phone screens.
            </div>,
            date: '12/12/2019',
        },
        {
            description: <div>
                What to do when feeling sick?

                Learn the full spectrum of COVID-19 symptoms. The most common symptoms of COVID-19 are fever, dry cough and fatigue. Other less common symptoms that may affect some patients include loss of taste or smell, aches and pains, headache, sore throat, nasal congestion, red eyes, diarrhea, and rash.
                Stay home and isolate yourself even if you have mild symptoms such as cough, headache and mild fever, until you recover. Call your healthcare provider or hotline for advice. Ask someone else to bring you the purchases. And if you have to leave the house or summon someone to stay with you, wear a mask to avoid spreading the infection to others.
                If you have a fever, cough and difficulty breathing, seek medical attention immediately. Phone first if you can, and follow directions from your local health authority.
            </div>,
            date: '12/12/2018',
        },
    ];