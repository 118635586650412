import React, { Component } from 'react';

import style from './Select.module.scss';

class Select extends Component
{
    render()
    {
        return (
            // set background if needed (recommend=> inline style selectBackColor={{backgroundColor: "#eaf6f6"}})
            <select className={`form-select ${style.formSelect}`} style={this.props.selectBackColor} aria-label="Default select example">
                {
                    this.props.items.map((item, index) =>
                    {
                        return <option className={style.option} key={index} value={item.value}>{item.label}</option>
                    })
                }
            </select>
        )
    }
}
export default Select;