import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../../logo.svg";
import { useRef } from 'react';
//import useForm from "./useForm";
//import validate from "./validationinfo";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";


//
//
function FormRegister()
{
  //const { handelChange, values, handelSubmit, errors } = useForm(validate);
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      sexe: "",
      birthday: "",
      password: "",
      confirmPassword: "",
      isAgree: "false"
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .trim()
        .required("Required")
        .matches(/[A-Za-z]{1,30}$/, "First name is invalid"),
      lastname: Yup.string()
        .trim()
        .required("Required")
        .matches(/[A-Za-z]{1,30}$/, "Last name is invalid"),
      username: Yup.string()
        .trim()
        .required("Required")
        .matches(/[A-Za-z]+([.]|[_])?([0-9]{1,5})?[a-zA-Z]+?([0-9]{1,5})?$/, "User name is invalide"),
      email: Yup.string()
        .required("Required")
        .trim()
        .matches(/[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, "Email adress is invalid"),
      birthday: Yup.string()
        .required("Required")
        .trim(),
      password: Yup.string()
        .matches(/([a-z]{6})/, "Password needs to be 6 caractres includ letter(a-Z) and number")
        .required("Required"),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "password does not match")
      })
        .matches(/([a-z]{6})/, "Password needs to be 6 caractres includ letter(a-Z) and number")
        .required("Required"),
    }),
    onSubmit: (values) =>
    {

      if (values.isAgree === 'false')
      {
        policyDiv.current.classList.remove('d-none');
        policyDiv.current.classList.add('d-block');
      } else
      {

        policyDiv.current.classList.remove('d-block');
        policyDiv.current.classList.add('d-none');


        var newDate = formatDate(values.birthday);
        var myObject = {
          'cin': null, 'firstName': values.firstname, 'lastName': values.lastname,
          'userName': values.username, 'email': values.email, 'phone': null,
          'birthDate': newDate, 'birthLocation': null, 'birthCity': null,
          'adress': null,
          'sexe': values.sexe, 'job': null,
          'approved': 0, 'offline': 0, 'disable': 0, 'password': values.password
        };
        console.log(myObject);

        // read all entities
        fetch("http://127.0.0.1:8000/api/v1/auth/register", {
          method: "POST",
          body: JSON.stringify(myObject),
          headers: {
            // "x-rapidapi-host": "fairestdb.p.rapidapi.com",
            // "x-rapidapi-key": "apikey"
            "content-type": "application/json",
            "accept": "application/json"
          },
        }).then(response => response.json())
          .then(response =>
          {
            console.log(response);
            //history.push("/register2");
          })
          .catch(err => { console.log(err); });
      }
    },
  });

  const policyDiv = useRef();
  const formatDate = (date) =>
  {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <div className="formRegister">
      <img src={logo} alt="logo" className="logo-dl" />
      <span className="vertical-line"></span>
      <Formik>
        <Form className="form-register" onSubmit={handleSubmit}>
          <h2>Sign Up</h2>
          <Row className="Row-Control">
            <Form.Group
              as={Col}
              controlId="formGroupFirstName"
              className="form-Control Form-Row"
            >
              <Form.Control
                type="text"
                placeholder="First name (*)"
                name="firstname"
                onBlur={handleBlur}
                id="firstname"
                value={values.firstname}
                onChange={handleChange}
                className={
                  touched.firstname && errors.firstname
                    ? "is-invalid"
                    : touched.firstname
                      ? "is-valid"
                      : ""
                }
              />
              {touched.firstname && errors.firstname ? (
                <div class="invalid-feedback d-block">{errors.firstname}</div>
              ) : touched.firstname ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGroupLastName"
              className="form-Control Form-Row"
            >
              <Form.Control
                type="text"
                placeholder="Last name (*)"
                name="lastname"
                id="lastname"
                onBlur={handleBlur}
                value={values.lastname}
                onChange={handleChange}
                className={
                  touched.lastname && errors.lastname
                    ? "is-invalid"
                    : touched.lastname
                      ? "is-valid"
                      : ""
                }
              />
              {touched.lastname && errors.lastname ? (
                <div class="invalid-feedback d-block">{errors.lastname}</div>
              ) : touched.lastname ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>
          </Row>
          <Form.Group controlId="formGroupUserName" className="form-Control">
            <Form.Control
              type="text"
              placeholder="User name (*)"
              name="username"
              onBlur={handleBlur}
              value={values.username}
              onChange={handleChange}
              className={
                touched.username && errors.username
                  ? "is-invalid"
                  : touched.username
                    ? "is-valid"
                    : ""
              }
            />
            {touched.username && errors.username ? (
              <div class="invalid-feedback d-block">{errors.username}</div>
            ) : touched.username ? (
              <div class="valid-feedback d-block">valid</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="formGroupEmail" className="form-Control">
            <Form.Control
              type="email"
              placeholder="email (*)"
              name="email"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              className={
                touched.email && errors.email
                  ? "is-invalid"
                  : touched.email
                    ? "is-valid"
                    : ""
              }
            />
            {touched.email && errors.email ? (
              <div class="invalid-feedback d-block">{errors.email}</div>
            ) : touched.email ? (
              <div class="valid-feedback d-block">valid</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="formGroupCheckSexe" className="group-radio">
            <Form.Label className="lbl-radio">sexe (*)</Form.Label>

            <RadioGroup name="sexe" value={values.sexe} onChange={handleChange} className="groupeRadio" >
              <FormControlLabel
                control={<Radio />}
                label="man"
                value="M"
                defaultChecked

              //className="radio-man"
              />
              <FormControlLabel control={<Radio />} value="F" label="woman" />

            </RadioGroup>
          </Form.Group>
          <Form.Group controlId="formGroupBirthday" className="form-Control">
            <Form.Control
              type="date"
              name="birthday"
              onBlur={handleBlur}
              value={values.birthday}
              onChange={handleChange}
              className={
                touched.birthday && errors.birthday
                  ? "is-invalid"
                  : touched.birthday
                    ? "is-valid"
                    : ""
              }
            />
            {touched.birthday && errors.birthday ? (
              <div class="invalid-feedback d-block">{errors.birthday}</div>
            ) : touched.birthday ? (
              <div class="valid-feedback d-block">valid</div>
            ) : null}
          </Form.Group>
          <Row className="Row-Control">
            <Form.Group
              as={Col}
              controlId="formGroupPassword"
              className="form-Control Form-Row"
            >
              <Form.Control
                type="password"
                placeholder="Password (*)"
                name="password"
                onBlur={handleBlur}
                value={values.password}
                onChange={handleChange}
                className={
                  touched.password && errors.password
                    ? "is-invalid"
                    : touched.password
                      ? "is-valid"
                      : ""
                }
              />
              {touched.password && errors.password ? (
                <div class="invalid-feedback d-block">{errors.password}</div>
              ) : touched.password ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGroupConfirmedPassword"
              className="form-Control Form-Row"
            >
              <Form.Control
                type="password"
                placeholder="Confirm Password (*)"
                name="confirmPassword"
                onBlur={handleBlur}
                value={values.confirmPassword}
                onChange={handleChange}
                className={
                  touched.confirmPassword && errors.confirmPassword
                    ? "is-invalid"
                    : touched.confirmPassword
                      ? "is-valid"
                      : ""
                }
              />
              {touched.confirmPassword && errors.confirmPassword ? (
                <div class="invalid-feedback d-block">{errors.confirmPassword}</div>
              ) : touched.confirmPassword ? (
                <div class="valid-feedback d-block">valid</div>
              ) : null}
            </Form.Group>
          </Row>
          <Form.Group controlId="formGroupCheck" className="form-Control">
            <div style={{ display: 'flex' }}>
              <Form.Check
                type="checkbox"
                label="I agree to the,"
                name="isAgree"
                onBlur={handleBlur}
                value={values.isAgree}
                onChange={handleChange}
              />
              <Link to='/privacy_policy'>privacy policy </Link> and <Link to='/terms'>Terms</Link>
            </div>
            <div ref={policyDiv} className="invalid-feedback d-none">you must agree to the privacy policy</div>

          </Form.Group>

          <Button
            type="submit"
            variant="primary"
            className="btn-create-register2"
          >
            Create Account
          </Button>

          <Link to="/register2" className='btn btn-outline-secondary'>next step</Link>

          <hr></hr>
          <div className="div-footer-register2">
            <label className="lbl-info-register2">(*) Required fields</label>
            <div className="div-info-register2">
              <label className="lbl-info2-register2">
                if you have an account on DoniteLife
              </label>
              <Link to="/login">
                <Button
                  type="button"
                  variant="primary"
                  className="btn-login-register2"
                >
                  Login
                </Button>
              </Link>
            </div>
          </div>
        </Form>
      </Formik>
    </div >
  );
};

export default FormRegister;
