export const HospitalCenterHome =
    [
        {
            option: 'Hospital Tetouan',
            key: '1'
        },
        {
            option: 'Hospital Tanger',
            key: '2'
        },
        {
            option: 'Hospital Rabat',
            key: '3'
        },
        {
            option: 'Hospital Marakech',
            key: '4'
        },
        {
            option: 'Hospital Agadir',
            key: '5'
        },
        {
            option: 'Hospital EL Hosima',
            key: '6'
        },
        {
            option: 'Hospital Ouejda',
            key: '7'
        },
        {
            option: 'Hospital Taourirt',
            key: '8'
        },
        {
            option: 'Hospital Chefchaouen',
            key: '9'
        },
        {
            option: 'Hospital Asfi',
            key: '10'
        },
        {
            option: 'Hospital Khribga',
            key: '11'
        },
        {
            option: 'Hospital Setat',
            key: '12'
        },
        {
            option: 'Hospital Laeyoune',
            key: '13'
        },
        {
            option: 'Hospital Ourzazat',
            key: '14'
        },
        {
            option: 'Hospital Taounat',
            key: '15'
        },
        {
            option: 'Hospital Errachidia',
            key: '16'
        },
        {
            option: 'Hospital Nador',
            key: '17'
        },
    ];