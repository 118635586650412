import React, { Component } from "react";
import { Navbar, Form, NavDropdown } from "react-bootstrap";
import { IoGlobeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import logo from "../../../../logo.svg";

class NavbarHead extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }
  render() {
    return (
      <Navbar className="navbar-register2" expand="lg" ref={this.wrapper}>
        <Link to="/"> 
        <img src={logo} className="App-logo" alt="logo" />
        </Link>
          <Navbar.Brand className="nv-brand">DoniteLife</Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
          <Form className="nav-from-register2">
            <NavDropdown title={<IoGlobeOutline />} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.3">English</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Français</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Arabic</NavDropdown.Item>
            </NavDropdown>
          </Form>
        {/* </Navbar.Collapse> */}
      </Navbar> 
    );
  }
}

export default NavbarHead;
